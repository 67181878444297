import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NAVIGATOR_SCREEN_DETAILS, NAVIGATOR_THEME } from '../../utility/constants';
import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';
import { ProjectSummaryService } from '@projects/projectsummary/src/app/projectsummary/project-summary.service';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { IIDRService } from '@projects/iidr/src/app/iidr/iidr.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { BUS_MESSAGE_KEY } from 'src/app/shared/constants/message-bus';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';

export interface ScreenDetail {
  name: string;
  path: string;
  translatedName: string;
}

@Component({
  selector: 'app-quick-navigator',
  templateUrl: './quick.navigator.component.html',
  styleUrls: ['./quick.navigator.component.scss']
})
export class QuickNavigatorComponent implements OnInit, OnDestroy {

  @Input() projectId: number;
  @Input() currentScreenName?: string;
  @Input() theme?: string = NAVIGATOR_THEME[0];
  @Output() setProjectInfoData = new EventEmitter();

  screenOptions: ScreenDetail[] = NAVIGATOR_SCREEN_DETAILS;
  activityRedirectData;
  projectDetailsSubscription: Subscription;
  subscriptions: Subscription[]= [];
  packageId;
  functionId;
  defaultTheme = NAVIGATOR_THEME[0];
  languageId: number = 1;

  constructor(private router: Router,
    private cryptUtilService: CryptUtilService,
    private projectSummaryService: ProjectSummaryService,
    private iidrService: IIDRService,
    private messagingBus: MessagingService,
    private userPreference: UserPreferenceService
    ) { }


  ngOnInit(): void {
    this.subscriptions.push(
      this.userPreference.getLanguageId().subscribe((langId) => {
        this.languageId = +langId
      })
    );
  }

  goto(name, path) {
    switch (name) {
      case "Project Methodology":
        this.setSelectedFilterValues(path);
        // this.router.navigate([`${path}`]);
        break;
      case 'Current Activity':
        this.getCurrentActivity();
        break;
      case 'Project Site Map':
        this.setPackageFunctionObj(this.projectId);
        break;
      default:
        this.router.navigate([`${path}` + `${this.projectId}`]);
        break;
    }
  }

  getCurrentActivity() {
    this.cryptUtilService.setItem('CURRENT_ACTIVITY_CLICKED', true, 'SESSION');
    this.prepareActivityURLInputs();
  }

  setSelectedFilterValues(path) {
    this.messagingBus
      .subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
        let user = data?.projectDetails?.userId ?? data.userId;
        let params = new HttpParams().set("userid", user);
        this.projectDetailsSubscription = this.projectSummaryService.getProjectInfo(this.projectId, params).subscribe(response => {
          if (response && response[0]?.package_id && response[0]?.functions) {
            let previousSessionIIDRFunctionSelected = this.cryptUtilService.getItem('Previous-Session-Function-Selected-IIDR-Project', 'SESSION');
            if(!previousSessionIIDRFunctionSelected && response?.[0].functions?.length > 0){
              this.cryptUtilService.setItem('Previous-Session-Function-Selected-IIDR-Project', response?.[0].functions?.[0].serviceName , 'SESSION');
            }
          }
           this.router.navigate([`${path}`]);
        })
      })
  }

  prepareActivityURLInputs() {
    this.subscriptions.push(
    this.messagingBus
      .subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
        let user = data?.projectDetails?.userId ?? data.userId;
        let params = new HttpParams().set("userid", user);
        this.projectDetailsSubscription = this.projectSummaryService.getProjectInfo(this.projectId, params).subscribe(response => {
          if (response && response[0]?.package_id && response[0]?.functions) {
            let previousSessionIIDRFunctionSelected = this.cryptUtilService.getItem('Previous-Session-Function-Selected-IIDR-Project', 'SESSION');
            if(!previousSessionIIDRFunctionSelected && response?.[0].functions?.length > 0){
              this.cryptUtilService.setItem('Previous-Session-Function-Selected-IIDR-Project', response?.[0].functions?.[0].serviceName , 'SESSION');
            }
            let functionId = response?.[0]?.functions?.find(d=> d.serviceName === previousSessionIIDRFunctionSelected)?.id
            this.functionId = functionId ? functionId : response?.[0].functions?.[0].id;
            this.packageId = response?.[0].package_id;

            this.iidrService
              .getTrainStopData(
                this.projectId,
                this.packageId,
                this.functionId,
                this.languageId
              ).subscribe((response) => {
                let phaseData = response?.data;
                let activityRedirectURL = '';
                let lastTrainstop = '';
                let initialActivitCall;
                for (const phase of phaseData) {
                  for (const stop of phase?.trainstops) {
                    if (stop?.isactive === "true" && (stop?.stop_status === "incomplete" || !stop?.stop_status)) {
                      activityRedirectURL = `/trainstops/activities/${phase?.phase_name}/${stop?.trainstop_id}`;
                      initialActivitCall = {
                        stopId: stop?.trainstop_id,
                        projectId: this.projectId,
                        packageId: this.packageId,
                        functionId: this.functionId
                      };
                      break;
                    } else {
                      lastTrainstop = `/trainstops/activities/${phase?.phase_name}/${stop?.trainstop_id}`;
                    }
                  }
                  if (activityRedirectURL?.length > 0) break;
                }
                if (activityRedirectURL?.length > 0) {
                  this.router.navigate([`${activityRedirectURL}`]);
                } else {
                  if(lastTrainstop?.length > 0) {
                    this.router.navigate([`${lastTrainstop}`]);
                  }
                }
              })
          }
        });
      })
      );
  }

  openQuickNavigator(){
    this.setProjectInfoData.emit(true);
  }

  setPackageFunctionObj(projectId:any) {
    let user = this.cryptUtilService.getItem('userInfo', 'SESSION');
    let params = new HttpParams().set("userid", user.userId?.toString());
    let projectInfo, jiraProjectKey, previousSessionIIDRFunctionSelected;
    this.projectSummaryService.getProjectInfo(projectId, params).subscribe((data: any) => {
      if (data) {
        projectInfo = data[0];
        let temp_obj;
        jiraProjectKey = data[0].jiraProjectKey;
        previousSessionIIDRFunctionSelected = this.cryptUtilService.getItem('Previous-Session-Function-Selected-IIDR-Project', 'SESSION');
      if(previousSessionIIDRFunctionSelected){
        projectInfo?.functions.forEach((item) => {
          if(previousSessionIIDRFunctionSelected.toLowerCase() == item.serviceName.toLowerCase()){
            temp_obj={
             package_id:projectInfo?.package_id,
             function_id:item?.id,
             user_role_id:projectInfo?.user_role_id,
             is_jira_project:projectInfo?.requestJiraInstance,
             function_name:item?.serviceName
           }
         }
        });
        
      }
      else{
        this.cryptUtilService.setItem('Previous-Session-Function-Selected-IIDR-Project', projectInfo.functions?.[0].serviceName , 'SESSION');
        let func_id;
        // projectInfo?.functions?.some((e, i)=> {
        //   if(e.serviceName == "Core") {
        //     func_id = e.id
        //   }
        // });
        // if(!func_id)
        func_id = projectInfo?.functions[0]?.id 

         temp_obj={
          package_id:projectInfo?.package_id,
          function_id:func_id,
          user_role_id:projectInfo?.user_role_id,
          is_jira_project:projectInfo?.requestJiraInstance,
          function_name:projectInfo?.functions[0]?.serviceName
        }
      }
        this.projectSummaryService.package_function_obj.next(temp_obj);
        this.router.navigate(['/sitemap/' + `${this.projectId}`]);
      }
    })
  }

  ngOnDestroy(): void {
    this.projectDetailsSubscription?.unsubscribe();
    this.subscriptions?.forEach((sub) => {
      sub.unsubscribe();
    });
  }

}
