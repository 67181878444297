export const EMAIL_REGEX = '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$';
export const NUMBER_PATTERN = /\-?\d*\.?\d{1,2}/;
export const DOMAIN_VALIDATOR = /^([a-z0-9@.-]+[a-z]\.[a-z]{2,4})$/i;
export const ALPHA_NUM = /^[a-zA-Z0-9]+$/i;
export const CUSTOMCHAR_REGEX = /^[a-zA-Z0-9 &.,_;:()|!-]+$/i;

// important for psg page.. do not change
export const PSG_STEPS = {
  CLIENT_DETAILS: 'engagement-details/client-description',
  PROJECT_DETAILS: 'engagement-details/project-description',
  SERVICE: 'scope/service',
  GEOGRAPHY: 'scope/geography',
  PROCESS: 'scope/process',
  PACKAGE_SUBFUNCTIONS: 'scope/sub-function',
  MODULE: 'scope/modules',
  SYSTEM: 'scope/system',
  RELEASE_DETAILS:'release/implementation-approach',
  PHASE1: 'phase-planning/gantt-chart',
  CONVERSION: 'technical-scope/conversion',
  REPORT: 'technical-scope/report',
  INTERFACE: 'technical-scope/interface',
  EXTENSION: 'technical-scope/extension',
  ASSUMPTIONS: 'assumptions',
  ASSUMPTION: 'assumption',
  DEVELOPMENT: 'technical-scope/development',
  DATA: 'technical-scope/data',
  OCM: 'technical-scope/OCM',
  INTEGRATION: 'technical-scope/integrations',
  ENHANCEMENT: 'technical-scope/enhancements',
  REVIEW: 'review',
  OFFERING_PORTFOLIO: "offering_portfolio",
  // Adding these constants for hiding download menu in support role as per US#2855248
  PSG_PAGE :"PSG",
  NextGen_Download :"Download Scope- NextGen extract(.xlsx)",
  Project_Document_Download:'Download Scope- Project document(.doc)',
  Download_Scope:"Download Scope",
  Release_Planning_Download:"Release-Planning- Download",
  TECHNICAL_SCOPE_Conversion_Doownload:"Technical Scope/ Conversion- Download"
};

export const DASHBOARD_TILES = {
  METHODOLOGY:  1,
  PROCESS_TAXONOMY: 2,
  USER_STORIES: 3,
  RELEASE_PLAN: 4,
  TEST_CASES: 5,
  PROJECT_TIMELINE: 6,
  AMPLIFIERS: 7,
  SITEMAP: 8,
  DOCUMENTS: 9,
  PROJECT_TOOLS: 10,
  DESIGN_COMPLETE: 11
}
//Adding these constants for hiding download menu in support role as per US#2855253
export const PROJECT_SUMMARY = {
  PAGE :"Project Summary",
  DELETE_PROJECT:"Delete project"
}

export const TOOLTIPS = {
  COMPLEXITY_INFO : 'Click the icon to download the information on complexities'
}

export const CR_ARCHIVE_CONST = {
  CR_ARCHIVE_POPUP_TITLE : 'Change request archive',
  CR_LABEL : 'Change Request',
  CR_ARCHIVE_DESCRIPTION : 'The archive contains previous versions of Change Requests. You can download a previous version if necessary.',
  DOWNLOAD_BTN_PATH : 'assets/psg/download-btn.svg',
}

export const EC_SYNC_STATUS = ['requested', 'in progress', 'approved']
export const CR_TOOLTIPS = ['Global.Constants.CRTooltips.SendUpdatesToEC','Global.Constants.CRTooltips.UpdatesInProgressEC','Global.Constants.CRTooltips.ReceivedUpdatesEC']
export const CR_STATUS_APPLICABLE_TABCODES = ['PROCESS_FLOWS_FEATURE','USER_STORIES','USER_STORY_LIBRARY']
export const PLACEHOLDERS = {
  CLIENT_DESC: 'Enter the required project information below.',
  PROJECT_DESC: 'Enter the required project information below.',
  SCOPE_SERVICE: 'Please select the scope for this project.',
  SCOPE_GEOGRAPHY: 'Choose the locations applicable to this project.',
  SCOPE_PROCESS: 'Processes encompass the key feature or functions required to deliver the selected Service(s). Selected at least one (or more) based on project scope.',
  SCOPE_MODULE: 'Assign modules to processes at L3 level if applicable to project scope.',
  SCOPE_SYSTEM: 'Systems are designed, developed, tested, and deployed. Select as needed based on project scope.',
  SCOPE_SAP_SUBFUNCTION:'The Package subfunctions identify and analyzes value-driven changes desired by the organization.  Add subfunctions that are applicable to the project scope and assign complexity.',
  RELEASE_IMPLEMENT:'Select Big Bang for a single release of a project, or Releases for more than one release of a project.',
  RELEASE_PLANNING: 'Set start and end dates for each subphase using the create subphase button. Phases can be consecutive, overlapping, or have gaps.',
  TECHNICAL_CONVERSION: 'Add the conversions that are applicable to the project scope.  Apply a method, system, volume, and complexity.',
  TECHNICAL_REPORT: 'Add the reports that are applicable to the project scope. Apply a type and complexity.',
  TECHNICAL_INTERFACE: 'Add the interfaces that are applicable to the project scope. Apply a system, type, and complexity.',
  TECHNICAL_EXTENSION: 'Add the extensions that are applicable to the project scope.',
  TECHNICAL_ASSUMPTIONS: 'Add assumptions that are applicable to the project scope.',
  TECHSCOPE_SAP_DEVELOPMENT:'The Development discipline is used to detail and create the technical system.  It includes both configurable and custom-developed application functions. Add development options that are applicable to the project scope and add complexity and quantity.',
  TECHSCOPE_SAP_DATA:'The Data discipline supports an organization’s data and information assets needs, including value, usefulness, accessibility, and security. Data activities include defining, assessing, transforming, and managing information architecture. Select the data that are applicable to the project scope and apply a complexity and quantity.',
  TECHSCOPE_SAP_OCM:'The Organizational Change Management discipline helps facilitate better adoption and sustainability of organizational change initiatives. The discipline encompasses a set of activities for an integrated approach to communications, stakeholder engagement and preparation, training, and organizational alignment and transition. Add activities that are applicable to the project scope and apply a quantity.',
  TECHSCOPE_SAP_ENHANCEMENT:'Dummy Data',
  TECHSCOPE_SAP_INTEGRATION:'Dummy data',
  REVIEW_PLACEHOLDER:'PSG.Review.Subheader.Placeholder'
};
export const PSG = 'psg';
export const ENGAGEMENT_DETAILS = 'engagement-details';
export const SCOPE = 'scope';
export const RELEASE = 'release';
export const TECHNICAL_SCOPE = {
  CONVERSION:'Conversion',
  EXTENSION:'Extension',
  INTERFACE:'Interface',
  REPORT:'Reports',
  DEVELOPMENT : 'Development',
  DATA : 'Data',
  OCM : 'OCM'
}
export const CONVERSION = 'conversion';
export const EXTENSION = 'extension';
export const INTERFACE = 'interface';
export const REPORT = 'report';
export const DEPLOYMENT = 'deployment';
export const ASSUMPTIONS = 'assumptions';
export const ASSUMPTION = 'assumption';
export const CLIENT_DESCRIPTION = 'client-description';
export const TECHNICALSCOPE = 'technical-scope';
export const PROJECT_DESCRIPTION = 'project-description';
export const SYSTEM = 'system';
export const GEOGRAPHY = 'geography';
export const SERVICE = 'service';
export const PROCESS = 'process';
export const WORKDAY_BUSINESS_PROCESS = 'business-process-scope';
export const ORACLE_BUSINESS_PROCESS = 'business-process-scope-oracle';
export const SPRINT = 'sprint';
export const MODULES = 'modules';
export const PACKAGE_SUBFUNCTIONS = 'package-subfunctions';
export const PHASE_PLANNING = 'phase-planning';
export const IMPLEMENTATION_APPROACH = 'implementation-approach';
export const GANTTCHART = 'gantt-chart';
export const REVIEW = 'review';
export const DEVELOPMENT = 'development';
export const TESTING = 'testing';
export const DATA = 'data';
export const OCM = 'OCM';
export const ENHANCEMENT = 'enhancements';
export const INTEGRATION = 'integrations';
export const UNSAVED_CHANGES = 'unsavedChanges';
export const INDUSTRY_PRINT = 'Industry Print';
export const REQUIRED_FIELDS = 5;
export interface stepdetails {
  selectMethods: boolean,
  service: boolean,
  preview: boolean
}

export const COMPLEXITY_LIST = {
  veryComplex: "Very Complex",
  complex: "Complex",
  medium: "Medium",
  simple: "Simple"
}

export const PROCESS_SCOPE_COMPLEXITY_LIST = [
  'Very Complex',
  'Complex',
  'Medium',
  'Simple',
];

export const SCOPE_FIELDS = [
  {value: 'In Scope', selected: false},
  {value: 'Out of Scope', selected: false},
]

export const REGION_LIST = ['Asia Pacific', 'Europe, Middle East and Africa', 'Latin America', 'North America'];

export interface urlDataObject {
  projectId : number,
  module : string,
  page : string,
  package? : string,
  isWdPackage? : boolean,
  languageId? : number
}

export const SCOPE_CR_STATUS_PENDING = 'PENDING';
export const SCOPE_CR_STATUS_COMPLETED = 'COMPLETED';
export const SCOPE_CR_STATUS_APPROVED = 'APPROVED';
export const SCOPE_CR_STATUS_FALSE = 'FALSE';
export const SCOPE_CR_STATUS_ERROR = 'ERROR';
export const CHANGE_REQUEST_PAGE_TITLE = 'Change Request Form';
export const ECON_UPDATES_PENDING = 'The scope has been updated and is waiting to be sent to ECON. Synchronize updates with ECON Product Management Tool. Follow the activity link to sync.'
export const ECON_UPDATES_SYNCED = 'Successfully. Updates are synchronized with ECON.';
export const ECON_UPDATES_SYNC_REQUESTED = 'Updates Request has been sent. A response from ECON is awaited.'
export const NON_ECON_UPDATES_SYNC_PENDING = 'You have successfully initiated a change request. Please go to the activity - to Refine Process Taxonomy';
export const TOOL_PROV_TABCODE = 'TOOLS_PROVISION';
export const PROCESS_FLOW_TABCODE = 'PROCESS_FLOWS_FEATURE';

export const EC_SYNC_STATUS_COLOR_CODE_MAP = {
  'pending' : {status : 'pending', display_text: 'Updates Pending', color: '#ED8B00', disable:false , visible:true},
  'requested' : {status : 'requested', display_text: 'Updates Requested', color: '#ED8B00', disable:true , visible:true},
  'in progress': {status : 'in progress', display_text: 'Updates Requested', color: '#ED8B00', disable:true, visible:true},
  'approved': {status : 'approved', display_text: 'Updates Synced', color: '#6FC2B4', disable:true, visible:false},
  'failed': {status : 'failed', display_text: 'Sync Error', color: '#FF5943', disable:false , visible:true},
  'error': {status : 'error', display_text: 'Sync Error', color: '#FF5943', disable:false , visible:true},
};

export const EC_SYNC_INFO_POPUP = {
  title : 'Sending updates to Enterprise Consciousness',
  statement : 'Activities.ToolsProvision.DeliveryTools.EcSyncPopup.Statement',
  step1 : 'Activities.ToolsProvision.DeliveryTools.EcSyncPopup.Step1',
  step1_error : 'Activities.ToolsProvision.DeliveryTools.EcSyncPopup.Step1.Error',
  step2 : 'Activities.ToolsProvision.DeliveryTools.EcSyncPopup.Step2',
  step2_error : 'Activities.ToolsProvision.DeliveryTools.EcSyncPopup.Step2.Error'
}

export const EC_UPDATE_SCOPE = 'You have updated scope for the project. Please go to the Tools Provisioning activity to export changes to EC';
export const EC_UPDATE_SCOPE_ESTIMATION_NO = 'You have successfully initiated a change request. Please navigate to the Tool provisioning activity to restore EC integration.';
export const NON_ECON_UPDATE_SCOPE_CR_PENDING = 'You have updated scope for the project. Please go to the activity - to Refine Process Taxonomy';
export const CR_TEST_AI = {
  HEADING : 'Activities.ActivityHandler.ChangeRequestTestAI.Heading',
  CONTENT_1: 'Activities.ActivityHandler.ChangeRequestTestAI.Content1',
  CONTENT_2: 'Activities.ActivityHandler.ChangeRequestTestAI.Content2',
}

export const TIMEOUT_US_EXPORT_MSG = "Due to large number of records the 'Export to Jira' did not get completed. Please retry after sometime.";

export const noNewUSToExport='No new user stories available to export.'
export const TOOLTIP_EC_SYNC = {
  est_req_yes : 'Activities.ToolsProvision.DeliveryTools.Tooltip.ECSync.Yes',
  est_req_no : 'Activities.ToolsProvision.DeliveryTools.Tooltip.ECSync.No'
}

export const TOOLS = {
  enterprise_consciousness : 'Enterprise Consciousness'
}

export const ALLOWEDSPECIALCHARTEXT = 'Only - & . , ; : ( ) | ! _ are allowed as special characters'

export const PSG_ARCHETYPE_ERRORS = {
  MISSING_ARCHETYPE:412,
}

export const NODE_LEVEL = {
  L1 : 'l1',
  L2 : 'l2',
  L3 : 'l3'
}

export const LANGUAGE_DATA = {
  ENGLISH : 1,
}
export const FUNCTION_DATA = {
  CORE : 'Core',
  CORE_LENGTH :82,
  ADDITIONAL_LENGTH1:10,
  ADDITIONAL_LENGTH2:35,
}

export const DETAILS_STATE = {
  OPEN : 'open',
  CLOSED : 'closed'
}


export const PROJECT_MENU = {
  GENERAL_DETAILS: 'general-details',
  CUSTOMIZE_METHOD_DETAILS: 'customize-method-details',
  ADDITIONAL_SCOPING_DETAILS: 'additional-scoping-details',
  TOOLS: 'tools',
  REVIEW: 'review',
}

export const CREATE = "create";
export const UPDATE = "update";

export const GENERAL_DETAILS = {
  ENGAGEMENT: 'engagement',
  PROJECT: 'project',
  CLIENT: 'client',
  SETTINGS: 'settings',
  OFFERING_PORTFOLIO: 'offering-portfolio',
  ADMINISTRATION: 'administration',
}

export const ADDITIONAL_SCOPING_DETAILS = {
  SCOPE: 'scope',
  GEOGRAPHY: 'geography',
  PROCESS: 'process',
  BUSINESS_PROCESS: 'business-process-scope',
  MODULES: 'modules',
  SYSTEM: 'system',
  SPRINT: 'sprint',
  PHASE_PLANNING: 'phase-planning',
  IMPLEMETATION_APPROACH: 'implementation-approach',
  GANTTCHART: 'gantt-chart',
  PACKAGE_SUBFUNCTIONS: 'package-subfunctions',
  TECHNICAL_SCOPE: 'technical-scope',
  CONVERSION: 'conversion',
  REPORT: 'report',
  INTERFACE: 'interface',
  EXTENSION: 'extension',
  ASSUMPTIONS: 'assumptions',
  ASSUMPTION: 'assumption',
  DEVELOPMENT: 'development',
  DATA: 'data',
  OCM: 'OCM',
  INTEGRATION: 'integrations',
  ENHANCEMENT: 'enhancements',
  DEPLOYMENT: 'deployment',
  TESTING: 'testing'
}

export const CUSTOMIZE_METHOD_DETAILS = {
  SELECT_METHOD : 'select-method',
  SERVICE : 'service',
  PREVIEW : 'preview',
  PROJECT_STATUS: 'project-status'
  
}

export const CUSTOM_METHOD_PATH = '/psg/customize-method-details/';
export const SELECT_METHOD_MSG = 'Answer Saved Successfully.';

export const PROJECT_STATUS = {
  DRAFT: 'DRAFT',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}
export const SERVICE_SAVE_MSG = 'Services Saved Successfully.';
export const SELECT_METHOD = 'Select Method';
export const PREVIEW = 'Preview';
export const TOOLTIP_TEXT = 'These sections are enabled only for projects created after the customize method feature implementation.'

export const PROJECT_STATUS_STEPS = [
  {
    status: "APPROVED",
    translatedTitle: 'ProjectStatus.Header.ProjectApproved',
    translatedContentHeading: 'ProjectStatus.Header.ProjectHasBeenApproved',
    translatedDescription: 'ProjectStatus.Header.CustomizingProject'

  },
  {
    status: "REJECTED",
    translatedTitle: 'ProjectStatus.Header.ProjectRejected',
    translatedContentHeading: 'ProjectStatus.Header.ProjectReviewedAndRejected',
    translatedDescription: 'ProjectStatus.Header.ProjectNotApproved'
  },
  {
    status: "PENDING_APPROVAL",
    translatedTitle: 'ProjectStatus.Header.projectApprovalPending',
    translatedContentHeading: 'ProjectStatus.Header.projectApplicationSuccessfullySubmitted',
    translatedDescription: 'ProjectStatus.Header.projectReviewed'

  }
]

export const BUTTONS = {
  NEXT_STEP: 'ProjectStatus.Header.ProjectNextStep',
  PREV_STEP: 'ProjectStatus.Header.ProjectPreviousStep',
  MY_PROJECT: 'ProjectStatus.Header.BackToMyProjects',
  SUBMIT_APPROVE: 'PreviewStatus.Header.Submitforapproval',
}
export const ADMIN_MODULE_DETAILS = {
CANCEL: 'Cancel',
ADMIN_MODULE_PAGE:'Admin Module - Velocity',
SAVE:'Save'

}

export const PROJECT_SETUP_TOOLS_PATH = {
  GENAI_AMPLIFIER: 'genai-amplifier',
  TRADITIONAL_AMPLIFIER: 'traditional-amplifier',
  MARKET_SOLUTION: 'market-solution'
}

export const PROJECT_SETUP_TOOLS = {
      1: PROJECT_SETUP_TOOLS_PATH.GENAI_AMPLIFIER,
      2: PROJECT_SETUP_TOOLS_PATH.TRADITIONAL_AMPLIFIER,
      3: PROJECT_SETUP_TOOLS_PATH.MARKET_SOLUTION
}
export const ADMIN_MODULE_TAXONOMY_DOCUMENTS_DETAILS = {
  Download: 'Download',
  ADMIN_MODULE_TAXONOMY_DOCUMENTS_PAGE:'Admin Module - Taxonomy Document',
  Edit:'Edit',
  Document: 'Document',
  Delete_File:'Delete File',
  UploadOrEdit:'Upload/Edit'  
  }

// Adding these constants as part of 2874347 - [Ext User] As a Package Admin, if I disable the External Users feature for my package, it should not be available for any projects which have not enabled it.
export const PROJECT_ADMIN = 'Project Admin';
export const External_User = 'External User';