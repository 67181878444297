import { DomSanitizer } from '@angular/platform-browser';
import { notificationData, notificationEventsTypes, PROJECT_TOOL } from './../../model/notification.config';
import { SystemNotificationService } from './../../services/system-notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SignalRService } from '../../services/signal-r.service';
import { CommonDialogueBoxComponent } from '../common-dialogue-box/common-dialogue-box.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DELETE_ALL_NOTIFICATIONS } from '../../constants/common-dialogue-box';
import {TranslateService} from '@ngx-translate/core';
import { ChatService } from '../../services/socket.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss']
})
export class NotificationMenuComponent implements OnInit {

  public notifications = [];
  isUnseenNotificationPresent: boolean;
  isUnreadNotificationPresent: boolean;
  totalRecords: number = 0;
  unreadRecords: number = 0;
  newMessage = '';
  messageList: any;
  pageSize: number = 20;
  pageNumber: number= 1;
  totalPages: number= 0;
  @ViewChild(CdkVirtualScrollViewport, {static: false}) public viewport: CdkVirtualScrollViewport;

  constructor(private sysNotificationService_: SystemNotificationService,
    private readonly sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private readonly signalRService_: SignalRService,
    private translate: TranslateService,
    private socket: ChatService
  ) { }

  ngOnInit(): void {
    this.sysNotificationService_.getNotificationAction().subscribe((res) => {
       if (res) {
        this.pageNumber = 1;
        this.notifications = [];
        this.getAllNotificationsData(this.pageNumber, this.pageSize);
      }
    })
    this.socket.getNewMessage().subscribe((message: string) => {
      this.messageList = message;
      this.notifications = [];
      this.getAllNotificationsData(1, this.pageSize);
    })
  }

  getAllNotificationsData(pageNumber, pageSize) {
    this.isUnreadNotificationPresent = false;
    this.sysNotificationService_.getallNotifications(pageNumber, pageSize).subscribe((res) => {
      if (res.data) {
        this.totalRecords =  res.data?.totalRecords;
        this.unreadRecords = res.data?.unreadRecords;
        this.totalPages = Math.ceil(this.totalRecords/this.pageSize);
        setTimeout(()=> {

        },2500)
        
        
        let data = res.data?.notificationData.map(notifObj => {
          let notificationConfigData = JSON.parse(JSON.stringify(notificationData));
          if(!notifObj.isSeen) {
            this.sysNotificationService_.setIsUnseenNotificationPresent(true);
          }
          if(!notifObj.isRead) {
            this.isUnreadNotificationPresent = true;
          }
          const projectApprovedRejectedURL = notificationConfigData.projectApprovedRejected.redirectionUrlData.url + notifObj.projectId;
          switch (notifObj.event) {
            case notificationEventsTypes.projectApproved:
              notifObj.notification = `The <span class="fw-bold">${notifObj.projectName}</span> has been <span class="fw-bold">Approved</span>`;
              notificationConfigData.projectApprovedRejected.redirectionUrlData.url = projectApprovedRejectedURL;
              notificationConfigData.project.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.projectApprovedRejected, ...notifObj };
            case notificationEventsTypes.projectCreated:
              notifObj.notification = `<span class="fw-bold">${notifObj.projectName}</span> is successfully created and is under review for approval</span>`;
              notificationConfigData.projectCreated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.projectCreated, ...notifObj };
            case notificationEventsTypes.projectRejected:
              notifObj.notification = `The Project <span class="fw-bold">${notifObj.projectName}</span> has been <span class="fw-bold">Rejected</span> due to reason <span class="fw-bold">${notifObj?.rejectReason}</span> `;
              notificationConfigData.projectApprovedRejected.redirectionUrlData.url = projectApprovedRejectedURL;
              notificationConfigData.project.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.projectApprovedRejected, ...notifObj };
            case notificationEventsTypes.psgGenerated:
              notifObj.notification =`PSG for project <span class="fw-bold">${notifObj.projectName}</span> has been <span class="fw-bold">Generated</span>`;
              notificationConfigData.psg.redirectionUrlData.url = notificationConfigData.psg.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.psg.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.psg, ...notifObj };
            case notificationEventsTypes.psgUpdated:
              notifObj.notification = `PSG for project <span class="fw-bold">${notifObj.projectName}</span> has been <span class="fw-bold">Updated</span>`;
              notificationConfigData.psg.redirectionUrlData.url = notificationConfigData.psg.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.psg.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.psg, ...notifObj };
            case notificationEventsTypes.toolGranted:
              notifObj.notification = `<span class="fw-bold">${notifObj?.featureName ?? PROJECT_TOOL}</span> for project <span class="fw-bold">${notifObj.projectName}</span> has been provisioned`;
              notificationConfigData.toolGranted.redirectionUrlData.url = notificationConfigData.toolGranted.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.toolGranted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.toolGranted, ...notifObj };
            case notificationEventsTypes.userAdded:
              notifObj.notification = `A project user was <span class="fw-bold">added</span> to project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.userAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.userAdded, ...notifObj };
            case notificationEventsTypes.usersAdded:
              notifObj.notification =  `Multiple users have been added to the project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.userAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.userAdded, ...notifObj };
            case notificationEventsTypes.jiraExportSuccess:
              notifObj.notification =  `Project <span class="fw-bold">${notifObj.projectName}</span> has exported Release Plan data to Jira.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.jiraExportFailed:
              notifObj.notification =  `Project <span class="fw-bold">${notifObj.projectName}</span> has failed to export Release Plan data to Jira. Please try again, or if persists, contact system administrator.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.testScenarioGenerationSuccess:
              notifObj.notification =  `Test scenarios for project <span class="fw-bold">${notifObj.projectName}</span> has been generated.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.testScenarioGenerationFailed:
              notifObj.notification =  `Project <span class="fw-bold">${notifObj.projectName}</span> has failed to generate test scenarios. Please try again, or if persists, contact system administrator.`;
              notificationConfigData.activity.redirectionUrlData.url = notificationConfigData.activity.redirectionUrlData.url + `${notifObj?.phaseName}/${notifObj?.stopId}/${notifObj?.activityId}/${notifObj?.tabCode}`;
              notificationConfigData.activity.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.activity, ...notifObj };
            case notificationEventsTypes.metaDataUpdated:
              notifObj.notification =  `${notifObj?.traceData}`;
              notificationConfigData.metaDataUpdated.redirectionUrlData.url = notificationConfigData.metaDataUpdated.redirectionUrlData.url;
              notificationConfigData.metaDataUpdated.metaDataLabels = [];
              return { ...notificationConfigData.metaDataUpdated, ...notifObj };
            case notificationEventsTypes.genAIUserAdded:
              notifObj.notification =  `${notifObj?.traceData} successfully <span class="fw-bold">added</span> to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.genAIUserAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIUserAdded, ...notifObj };
            case notificationEventsTypes.genAIUserDeleted:
              notifObj.notification =  `${notifObj?.traceData} was successfully <span class="fw-bold">deleted</span> from <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.genAIUserDeleted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIUserDeleted, ...notifObj };
            case notificationEventsTypes.genAIUserNotDeleted:
                notifObj.notification =  `<span class="fw-bold">Failed</span> to <span class="fw-bold">delete</span> ${notifObj?.traceData} from <span class="fw-bold">Ascend Intelligence Platform</span> for project ${notifObj.projectName}. Please contact support for assistance.`;
                notificationConfigData.genAIUserNotDeleted.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIUserNotDeleted, ...notifObj };
            case notificationEventsTypes.genAIUserNotAdded:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to <span class="fw-bold">add</span> ${notifObj?.traceData} to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.genAIUserNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIUserNotAdded, ...notifObj };
            case notificationEventsTypes.genAIBulkUsersAdded:
                notifObj.notification =  `The <span class="fw-bold">Users</span> are successfully added to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>`;
                notificationConfigData.genAIBulkUsersAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.genAIBulkUsersAdded, ...notifObj };
            case notificationEventsTypes.genAIBulkUsersNotAdded:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to add all <span class="fw-bold">users</span> to <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.genAIBulkUsersNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIBulkUsersNotAdded, ...notifObj };
              case notificationEventsTypes.genAIProjectAdded:
              notifObj.notification =  `<span class="fw-bold">Ascend Intelligence Platform</span> has been successfully provisioned for project <span class="fw-bold">${notifObj.projectName}</span>`;
              notificationConfigData.genAIProjectAdded.redirectionUrlData.url = notificationConfigData.genAIProjectAdded.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIProjectAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIProjectAdded, ...notifObj };
              case notificationEventsTypes.genAIProjectNotAdded:
                notifObj.notification =  `<span class="fw-bold">Failed</span> to provision <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
                notificationConfigData.genAIProjectNotAdded.redirectionUrlData.url = notificationConfigData.genAIProjectNotAdded.redirectionUrlData.url + notifObj.projectId;
                notificationConfigData.genAIProjectNotAdded.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
                return { ...notificationConfigData.genAIProjectNotAdded, ...notifObj };
            case notificationEventsTypes.genAIProjectMSTeamsIdUpdated:
              notifObj.notification =  `<span class="fw-bold">MS Teams</span> has been successfully synced with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
              notificationConfigData.genAIProjectMSTeamsIdUpdated.redirectionUrlData.url = notificationConfigData.genAIProjectMSTeamsIdUpdated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIProjectMSTeamsIdUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIProjectMSTeamsIdUpdated, ...notifObj };
            case notificationEventsTypes.genAIProjectMSTeamsIdNotUpdated:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to sync <span class="fw-bold">MS Teams</span> with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.genAIProjectMSTeamsIdNotUpdated.redirectionUrlData.url = notificationConfigData.genAIProjectMSTeamsIdNotUpdated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIProjectMSTeamsIdNotUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIProjectMSTeamsIdNotUpdated, ...notifObj };
            case notificationEventsTypes.aipSubUseCasesSynced:
              notifObj.notification =  `<span class="fw-bold">Gen AI Amplifier(s)</span> has been successfully synced with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>.`;
              notificationConfigData.aipSubUseCasesSynced.redirectionUrlData.url = notificationConfigData.aipSubUseCasesSynced.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.aipSubUseCasesSynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.aipSubUseCasesSynced, ...notifObj };
            case notificationEventsTypes.aipSubUseCasesNotSynced:
              notifObj.notification =  `<span class="fw-bold">Failed</span> to sync <span class="fw-bold">Gen AI Amplifier(s)</span> with <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.aipSubUseCasesNotSynced.redirectionUrlData.url = notificationConfigData.aipSubUseCasesNotSynced.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.aipSubUseCasesNotSynced.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.aipSubUseCasesNotSynced, ...notifObj };
            case notificationEventsTypes.genAIAmplifierDisabled:
              notifObj.notification =  `Gen AI Amplifier - <span class="fw-bold">${notifObj?.traceData}</span> is <span class="fw-bold">disabled</span> for the project <span class="fw-bold">${notifObj.projectName}</span>.`;
              notificationConfigData.genAIAmplifierDisabled.redirectionUrlData.url = notificationConfigData.genAIAmplifierDisabled.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIAmplifierDisabled.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIAmplifierDisabled, ...notifObj };
            case notificationEventsTypes.genAIProjectDetailsNotUpdated:
              notifObj.notification =  `<span class="fw-bold">Failed</span>  to update project details in <span class="fw-bold">Ascend Intelligence Platform</span> for project <span class="fw-bold">${notifObj.projectName}</span>. Please contact support for assistance.`;
              notificationConfigData.genAIProjectDetailsNotUpdated.redirectionUrlData.url = notificationConfigData.genAIProjectDetailsNotUpdated.redirectionUrlData.url + notifObj.projectId;
              notificationConfigData.genAIProjectDetailsNotUpdated.metaDataLabels = [`Project ID: ${notifObj.projectId}`];
              return { ...notificationConfigData.genAIProjectDetailsNotUpdated, ...notifObj };
              default:
              break;
          }});  
          
      this.notifications = [...this.notifications, ...data];
        
      }
    });
  }

  onScroll(event) {
    if (!event || !this.viewport || event === 0 || this.totalRecords < this.pageSize) {
      return;
    }
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      // Reached the bottom, load more data
      if(this.pageNumber < this.totalPages) {
        this.pageNumber = this.pageNumber + 1;
        this.getAllNotificationsData(this.pageNumber, this.pageSize);
      }
    }
  }

  deleteAllNotifications() {
    const dialogRef = this.dialog.open(CommonDialogueBoxComponent, {
      data: {
        from: DELETE_ALL_NOTIFICATIONS,
        title: "Navigation.Menu.Notification.Dialog.DeleteTitle",
        deleteMessageText: "Navigation.Menu.Notification.Dialog.DeleteMessage",
        buttonText: this.translate.instant("Navigation.Menu.Notification.Dialog.Confirm"),
        buttonContinueClass: true,
        cancelBtn: 'Navigation.Menu.Notification.Dialog.Cancel',
        buttonCancelClass: true,
      },

      maxWidth: "40rem",
      width: "35rem",
      height: "12rem",
      panelClass: "custom-dialog-container",
    });

     dialogRef.afterClosed().subscribe(response => {
      if(response) {
        this.sysNotificationService_.deleteAllNotification().subscribe((resp) => {
          if(resp) {
            this.sysNotificationService_.setNotificationAction(true);
          }
        });
      }
    });
  }

  markAllAsRead() {
    if (this.isUnreadNotificationPresent) {
      this.sysNotificationService_.readAllNotifications().subscribe((res) => {
        if (res) {
          this.isUnreadNotificationPresent = false;
          this.pageNumber = 1;
          this.getAllNotificationsData(this.pageNumber,this.pageSize);
        }
      })
    }
  }

  ngOnDestroy() {
    this.pageNumber = 1;
    this.notifications = [];
  }
}
