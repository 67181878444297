<section class="about-banner" id="about-banner" name="about-banner">
    <div class="about-header"><img src="assets/welcome/back.svg" alt="" loading="lazy" (click)=" goToWelcomePage()">Back
        to Deloitte Ascend</div>
    <div class="container">
        <div class="about-section">
            <div class="title">About Deloitte Ascend<sup>TM</sup></div>
            <div class="content">
                <div>To thrive in today's hyper competitive marketplace and digital age, company's need to innovate
                    beyond
                    just migration to the Cloud, they need to embrace a digital mindset and enable continuous innovation
                    throughout their operations</div>
                <div>Deloitte Ascend<sup>TM</sup> for Oracle is a digitally integrated platform that was launched at
                    OOW
                    2019 to help clients assess their current digital capabilities, map their digital journey, and
                    enable
                    the delivery of their digital ambitions. </div>
            </div>
        </div>
        <div class="divider-line"></div>

        <div class="digital-collaboration-section">
            <div class="col-md-6">
                <div class="title">Digital and collaboration technologies</div>
                <div class="content">Deloitte Ascend<sup>TM</sup> enables the full life cycle of our proprietary
                    Momentum Methodology
                    through the use of digital and collaboration technologies</div>
            </div>

            <div class="col-md-6 img-container "><img class="float-right" src="assets/welcome/digital-collaboration.svg"
                    alt="" loading="lazy"></div>
        </div>
        <div class="divider-line"></div>

        <div class="design-thinking">
            <div class="col-md-6 img-container"><img class="float-left" src="assets/welcome/design-thinking.svg" alt=""
                    loading="lazy">
            </div>
            <div class="col-md-6">
                <div class="title">Design Thinking<br>facilitation</div>
                <div class="content">Deloitte Ascend<sup>TM</sup> enables the full life cycle of our proprietary
                    Momentum Methodology through the use of digital and collaboration technologies</div>
            </div>
        </div>
        <div class="divider-line"></div>

        <div class="delivery-automation">
            <div class="col-md-6">
                <div class="title">Delivery Automation</div>
                <div class="content">Deloitte Ascend<sup>TM</sup> enables the full life cycle of our proprietary
                    Momentum Methodology
                    through the use of digital and collaboration technologies</div>
            </div>

            <div class="col-md-6 img-container"><img class="float-right" src="assets/welcome/delivery-automation.svg"
                    alt="" loading="lazy"></div>
        </div>
    </div>
</section>

<div class="footer-section">
    <div class="footer-text">
        <div (click)="navigateToTerms()">Terms of Use</div>
        <div (click)="navigateToPolicy()">Privacy</div>
    </div>
    <div class="copyright-text">
        <blockquote> Copyright @ {{copyrightDate}} Deloitte Development LLC. All rights reserved.
        </blockquote>
    </div>
</div>