import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { MsalModule, MsalAngularConfiguration, MsalInterceptor, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalService } from '@azure/msal-angular';
import { Configuration } from "msal";
import { environment } from 'src/environments/environment';
  
/*
export const protectedResourceMap: [string, string[]][] = [
    [environment.AUTH_RESOURCE_LINK.self, ['user.read']],
    [environment.AUTH_RESOURCE_LINK.users, ['user.read']]
];
*/

export const protectedResourceMap:[string, string[]][]=[
    [environment.AUTH_RESOURCE_LINK.self, ['user.read']],
    [environment.AUTH_RESOURCE_LINK.users, ['user.read']]
];

// if (!environment.isLocal) {
//     protectedResourceMap.push(
//     [`${environment.API_BASE_URL}/api/user?email=`,[environment.AUTH_RESOURCE_LINK.webAPI]]
//     )
//     protectedResourceMap.push(
//     [`${environment.API_BASE_URL}${environment.APIVERSIONURL}/user/list`,[environment.AUTH_RESOURCE_LINK.webAPI]]
//     )
// }

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;



function MSALConfigFactory(): Configuration {
    return {
      auth: {
        clientId: environment.AUTH_CONFIG.clientID,
        authority: environment.AUTH_CONFIG.authority,
        // validateAuthority: true,
        redirectUri: environment.AUTH_CONFIG.redirectUri,
        postLogoutRedirectUri: environment.AUTH_CONFIG.postLogoutRedirectUri,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE, // set to true for IE 11
      },
    };
  }
  
  function MSALAngularConfigFactory(): MsalAngularConfiguration {
    return {
      popUp: !isIE,
      consentScopes: ['#{consentScopes}#', "user.read", "offline_access"],
      unprotectedResources: ["https://www.microsoft.com/en-us/"],
      protectedResourceMap,
      extraQueryParameters: {}
    };
  }


@NgModule({
    imports: [MsalModule],
    exports: [MsalModule],
    providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: MSAL_CONFIG,
          useFactory: MSALConfigFactory
        },
        {
          provide: MSAL_CONFIG_ANGULAR,
          useFactory: MSALAngularConfigFactory
        },
        MsalService
      ]
})

export class AuthenticationModule {}