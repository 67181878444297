<ng-container *ngIf="isUnknownUser && isExternalUser">
  <app-external-user-signup></app-external-user-signup>
</ng-container>
<ng-container *ngIf="isUnknownUser == false">
  <section class="banner-container">
    <section class="logo-container">
      <div class="logo-box">
        <div class="wrapper">
          <div class="mt-3">
            <video [muted]="true" autoplay="autoplay" loop height="155" width="750">
              <source [src]="ascendLogoSource" type="video/mp4">
              {{"LandingPage.VideoTagError" | translate}}
            </video>
          </div>
        </div>
      </div>
    </section>

    <section class="introduction-container" *ngIf="!isDiscover">
      <div class="splash-content">
        <div class="title-block animation1">
          {{"LandingPage.Header.Title" | translate}}<sup class="title-super-block">TM</sup>
        </div>
        <div class="animation2 title-desc-block">
          <div class="title-wrap">{{"LandingPage.SubHeader.Title" | translate}}</div>
        </div>
        <div class="row justify-content-center homepage-card-container animation3">
          <mat-card class="col-3 mx-4" (click)="homepageActions('Discover')">
            <mat-card-header class="justify-content-center">
              <mat-card-title>{{'LandingPage.Cards.Discover.Title' | translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="justify-content-center">
              <p>
                {{"LandingPage.Cards.Discover.Content" | translate}}
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card class="col-3 mx-4" (click)="homepageActions('Execute')">
            <mat-card-header class="justify-content-center">
              <mat-card-title>{{'LandingPage.Cards.Execute.Title' | translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="justify-content-center">
              <p>
                {{"LandingPage.Cards.Execute.Content" | translate}}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </section>

    <section class="introduction-container discover animation4" *ngIf="isDiscover">
      <div class="splash-content">
        <div class="title-block">
          {{"LandingPage.DiscoverCards.Header" | translate}}
        </div>
        <div class="title-desc-block">
          <div class="title-wrap">{{"LandingPage.DiscoverCards.SubHeader" | translate}}</div>
        </div>
        <div class="row justify-content-center homepage-card-container">
          <mat-card class="col-1 mx-2" *ngFor="let card of discoverCards; let i=index;" 
          (click)="discoverAction(card.buttonName)" (mouseenter)="hovered = i" (mouseleave)="hovered = -i">
            <mat-card-header class="justify-content-center">
              <mat-card-title [style.background-image]="hovered == i ? 'url(../../../../'+card.iconHoverImage+')' : 'url(../../../../'+card.iconImage+')'"> 
                <!-- <img *ngIf="hovered == i" [src]="discoverCards[i].iconHoverImage">
                <img *ngIf="hovered !== i" [src]="card.iconImage"> -->
              </mat-card-title>
            </mat-card-header>
            <mat-card-content class="justify-content-center">
              <p>
                {{ card.content | translate}}
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <section class="back">
        <div (click)="backToMain()">
          <img src="assets/welcome/discover-icons/back.svg"> {{"LandingPageDiscoverCards.Back" | translate}}
        </div>
      </section>
    </section>

    <section class="footer-section animation1">
      <div class="nav-new-elements">
        <div class="me-4 nav-text" (click)="navigateToTerms()">
          {{"LandingPage.Menu.TermsOfUse" | translate}}
        </div>
        <div *ngIf="!isExternalUser" class="me-4 nav-text" (click)="navigateToPolicy()">
          {{"LandingPage.Menu.Privacy" | translate}}
        </div>
      </div>
      <div class="nav-copyright-element">
        <div class="nav-text copyright-nav">
          {{"LandingPage.Menu.Copyright" | translate}} @ {{copyrightDate}}
          {{"LandingPage.Menu.Copyright.LLC" | translate }}
        </div>
      </div>
    </section>
  </section>
</ng-container>
