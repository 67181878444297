import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PassGlobalInfoService } from './pass-project-global-info.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CryptUtilService } from './crypt-util.service';
import { BaseService } from '@projects/shared-lib/src/lib/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {

 private userPreferenceURL : string = `${environment.API_MICROSERVICE_URL?.USER}/user-preferences`;
 private methodPackageURL : string = `${environment.API_MICROSERVICE_URL?.METHOD}/package/package-methods`;
 private amplifierDeliverURL : string = `${environment.API_MICROSERVICE_URL?.PROJECT}/`;
 private exploreAmplifierDeliverURL : string = `${environment.API_MICROSERVICE_URL?.METHOD}/explore/deliverables-amplifiers`;

 public subjectData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public packageData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public functionData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public parentPackageData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public parentPackageIdData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public packageIdData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public functionIdData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public userReferenceData = new Subject<any>();
 public taxonomyIdData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
 public showSearchBox = new Subject<boolean>();
 public projectIdData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
public languageData: BehaviorSubject<number> = new BehaviorSubject<number>(null);
 API_MICROSERVICE_ADMIN : string = environment.API_MICROSERVICE_URL.ADMIN;  
 userRoleInfo;
 langId: number = 1;
  constructor(private http: HttpClient,
    private baseService: BaseService,
     private cryptUtilService: CryptUtilService,
     private globalData: PassGlobalInfoService, private router: Router) {
    this.globalData.share.subscribe(data => {
      this.userRoleInfo = data?.userRoleInfo;
    });
   }

  getUserPreferences(adminPackageId?: number): Observable<any> {
    if(adminPackageId)
    return this.http.get<any>( this.methodPackageURL + '/'+adminPackageId);
    else
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.METHOD +'/package/package-detail?statusId=1').map(res => this.getPckgResponse(res));
  }

  getPckgResponse(res) {
    res?.data?.forEach((item) => {
      item.name = item.package_method_nm;
      item.package_id = item.package_method_id;
      item.project_mode = item.is_project_mode_set ? 'Y' : 'N';
    })
    return res;
   }


  saveUserPreferences(data): Observable<any> {
    return this.http.post(this.userPreferenceURL, data);
  }

  /**
   * when user prefernce offering is unset, want the user to navigate to welcome screen.
   * usecase - when a platform admin deactivates a package & the same package was selected by another platform admin user then the response will give
   * a empty array.
  */
  getUserPreferencesData(userId:any): Observable<any> {
    return this.http.get<any>( this.userPreferenceURL+'/'+userId).pipe(map((res: any) => {
      if(res?.data && res?.data.length == 0) {
        this.router.navigate(['/welcome']);
      }
      return res;
    }))
  }

  getReqResponse(res) {
      res?.data.forEach((item) => {
        item.id = item.packageFunctionId;
        item.service_name = item.packageFunctionName;
      })
      return res;
  }

  getUserpreferenceFunction(): Observable<any> {
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.METHOD + '/package/package-function')
      .map(res => this.getReqResponse(res));
  }

  getUserPackageFunction(packageId: number): Observable<any> {
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.METHOD + '/package/package-function?isFunction=true&packageMethodId=' + packageId)
      .map(res => this.getReqResponse(res));
  }

    /*
    when package Admin offering gets disabled.
    we get list of all offerings in response redirecting the packageAdmin user
    to welcome screen to make him select user preference.
  */
  getUserPackageOfferings(userId, langId): Observable<any> {
    return this.http.get<any>(`${this.userPreferenceURL}/offering-packages/${userId}?languageId=${langId}`).pipe(map((res: any) => {
      if(res.data) {
        if(res.data.length > 1 && this.userRoleInfo?.isPackageAdmin) {
          this.router.navigate(['/welcome'])
        }else {
          return res;
        }
      }
    }))
  }



  public sendLanguageId(languageId: number){
    this.langId = languageId;
    this.languageData.next(languageId);
  }

  public sendUserId(userId:any):void{
    this.subjectData.next(userId);
  }

  public sendPackageData(packageVal:any):void{
    this.packageData.next(packageVal);
  }


  public sendFunctionData(functionVal:any):void{
    this.functionData.next(functionVal);
  }

  public sendOfferingData(oVal: any) {
    this.parentPackageData.next(oVal)
  }

  public sendOfferingIdData(oIdVal: any) {
    this.parentPackageIdData.next(oIdVal)
  }

  public sendPackageIdData(packageIDVal:any):void{
    this.packageIdData.next(packageIDVal);
  }

  public sendFunctionIdData(functionIDVal:any):void{
    this.functionIdData.next(functionIDVal);
  }

  getLanguageId(): Observable<number> {
    return this.languageData.asObservable();
  }
  getPackageData(): Observable<any> {
    return this.packageData.asObservable();
  }

  getParentPackageData(): Observable<any> {
    return this.parentPackageData.asObservable();
  }

  getParentPackageIdData(): Observable<any> {
    return this.parentPackageIdData.asObservable();
  }

  getUserPrefData(): Observable<any> {
    return this.userReferenceData.asObservable();
  }

  getFunctionData(): Observable<any> {
    return this.functionData.asObservable();
  }
  updateData(userPrefData: any) {
    this.userReferenceData.next(userPrefData);
  }

  getPackageIdData(): Observable<any> {
    return this.packageIdData.asObservable();
  }

  getFunctionIdData(): Observable<any> {
    return this.functionIdData.asObservable();
  }

  getAmplifierDeliverable(projectId, packageId, functionId): Observable<any> {
     return this.http.get<any>(this.exploreAmplifierDeliverURL +'/'+packageId+'/'+functionId);
  }

  showHideSearchInput(): Observable<boolean> {
    return this.showSearchBox.asObservable();
  }

  showSearchInput(popOpen) {
    this.showSearchBox.next(popOpen);
  }

  public sendProjectId(id:any):void{
    this.projectIdData.next(id);
  }
  getProjectId(): Observable<any> {
    return this.projectIdData.asObservable();
  }

  public sendTaxonomyIdData(taxonomyIDVal:any):void{
    this.taxonomyIdData.next(taxonomyIDVal);
  }

  getTaxonomyIdData(): Observable<any> {
    return this.taxonomyIdData.asObservable();
  }

  getTaxonomyFromSessionStorage() {
    return this.cryptUtilService.getItem(
      "Previous-Session-Taxonomy-Selected-IIDR-Explore",
      "SESSION"
    );
  }

  getSolutionPref(package_id: number, function_id: number, feature_id: number) {
    return this.baseService.get(
      `/package/preferences/${package_id}/${function_id}/${feature_id}?languageId=${this.langId}`
    , this.API_MICROSERVICE_ADMIN);
  }

  getFeatureList() {
    return this.baseService.get('/package/features/0', environment.API_MICROSERVICE_URL?.ADMIN);
  }
}
