
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (!environment.isLocal && window) {
  console.log = () => {};
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  let cookieScript = document.createElement("script");
  cookieScript.type = "text/javascript";
  cookieScript.async = true;
  cookieScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  cookieScript.setAttribute("data-domain-script",environment.COOKIE_CONSENT);
  document.body.appendChild(cookieScript);

  var launchScript = document.createElement('script');
  launchScript.type = 'text/javascript';
  if (JSON.parse(environment.production)) launchScript.src = "//assets.adobedtm.com/571f1a0c43d6/b821caed2815/launch-0eebf70e1ab1.min.js";
  else launchScript.src = "//assets.adobedtm.com/571f1a0c43d6/b821caed2815/launch-0ac577463a9c-development.min.js";
  document.getElementsByTagName('head')[0].appendChild(launchScript);