<div class="confirm_dialog_cont" [ngStyle]="{'position': data && data.from =='MARKETPLACE_SOLUTIONS' ? 'relative' : ''}">
  <!-- DIALOG HEADER -->
  <div class="dialog_header"  [ngClass]="{'dialog_header-text': data.from =='USER_PREFERENCES' || data.from =='PROJECT_USERS_DELETE' || data.from =='ADMIN_DELETE' || data.from =='CREATE_PROJECT'  || data.from =='PROJECT_SOFT_DELETE' || data.from == 'GENERATE_SPRINT' || data.from =='DELETE_ALL_NOTIFICATIONS' || data.from == 'DOWNLOAD_USER_STORY_SPRINT' || data.from == 'PROVISION_TOOL' || data.from == 'DOWNLOAD_REPORTS' , 'message-text-hide': data.messageText ==''}">
    <p class="title" mat-dialog-title  [ngClass]="{'title-text': data.from =='CREATE_PROJECT' || data.from =='USER_PREFERENCES' || data.from == 'DOWNLOAD_REPORTS'}" *ngIf="(data.from =='CREATE_PROJECT' || data.from =='USER_PREFERENCES' || data.from == 'DOWNLOAD_REPORTS')">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title warning" mat-dialog-title *ngIf="data.from == 'RETIRE_TAXONOMY'">
     {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from =='PROJECT_USERS_DELETE'}" *ngIf="data.from =='PROJECT_USERS_DELETE'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate}}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from =='ADMIN_DELETE'}" *ngIf="data.from =='ADMIN_DELETE'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate}}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from =='PROJECT_SOFT_DELETE' || data.from =='DELETE_ALL_NOTIFICATIONS'}" *ngIf="data.from =='PROJECT_SOFT_DELETE' || data.from =='DELETE_ALL_NOTIFICATIONS'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from == 'DOWNLOAD_USER_STORY_SPRINT'}" *ngIf=" data.from == 'DOWNLOAD_USER_STORY_SPRINT'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title" mat-dialog-title  [ngClass]="{'title-text delete-user-text': data.from == 'GENERATE_SPRINT'}" *ngIf=" data.from == 'GENERATE_SPRINT'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title title-text delete-user-text" mat-dialog-title *ngIf=" data.from == 'PROVISION_TOOL'">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <p class="title" mat-dialog-title   *ngIf="(data.from !='PROJECT_USERS_DELETE' && data.from !='USER_PREFERENCES' && data.from !='CREATE_PROJECT' && data.from !='PROJECT_SOFT_DELETE' && data.from !='ADMIN_DELETE' && data.from != 'DOWNLOAD_USER_STORY_SPRINT' && data.from != 'PROVISION_TOOL' && data.from != 'GENERATE_SPRINT' && data.from != 'RETIRE_TAXONOMY' && data.from !='DELETE_ALL_NOTIFICATIONS' && data.from != 'DOWNLOAD_REPORTS')">
      {{ (data?.title ? data?.title : 'Global.Common.Label.Message') | translate }}
    </p>
    <i class="icon close"  *ngIf="userPrefDataFound" (click)="closePopup()" [ngClass]="{'close-popup': data.from =='USER_PREFERENCES'}"></i>
    <i class="icon close" *ngIf="data.from =='CREATE_PROJECT'"   (click)="closePopup()" [ngClass]="{'close-popup': data.from =='CREATE_PROJECT'}"></i>
    <i class="icon close"  *ngIf="data.from =='PROJECT_USERS_DELETE' || data.from =='PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from == 'DOWNLOAD_USER_STORY_SPRINT' || data.from == 'GENERATE_SPRINT'" (click)="closePopup()" [ngClass]="{'close-popup': data.from =='PROJECT_USERS_DELETE' || data.from =='PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from == 'DOWNLOAD_USER_STORY_SPRINT' || data.from == 'GENERATE_SPRINT'}"></i>
    <i class="icon close popup"  *ngIf="data.from == 'PROVISION_TOOL' || data.from == 'DELETE_ALL_NOTIFICATIONS'" (click)="closePopup()" [ngClass]="{'close-popup': data.from == 'PROVISION_TOOL' || data.from == 'DELETE_ALL_NOTIFICATIONS'}"></i>
    <i class="icon close popup"  *ngIf="data.from == 'DOWNLOAD_REPORTS'" (click)="closePopup()" [ngClass]="{'close-popup-icon': data.from == 'DOWNLOAD_REPORTS'}"></i>
    <hr class="divider" *ngIf="data.from =='USER_PREFERENCES' || data.from =='PROVISION_TOOL' || data.from == 'DOWNLOAD_REPORTS'"/>
  </div>



  <!-- DIALOG CONTENT -->
  <mat-dialog-content class="mat-typography" [ngClass]="{'user-pref-padding': data && (data.from =='USER_PREFERENCES' || data.from =='CREATE_PROJECT'), 'delete-project-user-padding': data && data.from == 'PROJECT_USERS_DELETE' || data.from == 'PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from == 'DOWNLOAD_USER_STORY_SPRINT' || data.from == 'PROVISION_TOOL'  ||  data.from == 'RETIRE_TAXONOMY' || data.from == 'GENERATE_SPRINT' }">
    <p *ngIf="data.from !== 'DELETE_ALL_NOTIFICATIONS'" class="status_txt" [ngClass]="{'market-solution-msg': data && data.from =='MARKETPLACE_SOLUTIONS'}">{{data.message | translate}}</p>
    <p *ngIf="data.from !== 'DELETE_ALL_NOTIFICATIONS'" class="status_txt" [ngClass]="{'user-pref-txt': data && data.from == 'USER_PREFERENCES'}">{{data.messageText | translate}}</p>
    <p class="status_txt" *ngIf="data.from =='PROJECT_USERS_DELETE' " [ngClass]="{'delete-project-user-txt': data && (data.from == 'PROJECT_USERS_DELETE')}">{{data.deleteMessageText | translate}}</p>
    <p class="status_txt delete_status_txt" *ngIf="data.from == 'DELETE_ALL_NOTIFICATIONS'">{{data.deleteMessageText | translate}}</p>
    <p class="status_txt" *ngIf=" data.from =='PROJECT_SOFT_DELETE'" [ngClass]="{'delete-project-user-txt': data && data.from == 'PROJECT_SOFT_DELETE'}">{{data.projectName}} {{data.deleteMessageText | translate}}</p>
    <p class="status_txt" *ngIf=" data.from =='ADMIN_DELETE'" [ngClass]="{'delete-project-user-txt': data && data.from == 'ADMIN_DELETE'}">{{data.deleteMessageText}} {{data?.deleteAdminId}} +' '+{{'Shared.CommonDialogBox.label.Admin' | translate}}?</p>
    <p class="status_txt" *ngIf=" data.from =='DOWNLOAD_USER_STORY_SPRINT'" [ngClass]="{'delete-project-user-txt': data && data.from == 'DOWNLOAD_USER_STORY_SPRINT'}">{{data.downloadMessageText | translate}}</p>
    <p class="status_txt delete-project-user-txt" *ngIf="data.from =='PROVISION_TOOL' && !data.provisionMessage2" [ngClass]="{'delete-project-user-txt': data && data.from == 'PROVISION_TOOL'}">{{data.provisionMessage | translate}}</p>
    <ul *ngIf="data.from =='PROVISION_TOOL' && data.tool == 'Smart Offering' && data.provisionMessage2">
      <li class="status_txt delete-project-user-txt">
        <p [ngClass]="{'delete-project-user-txt': data && data.from == 'PROVISION_TOOL'}">{{data.provisionMessage | translate}}</p>
      </li>
      <li class="status_txt delete-project-user-txt">
        <p [ngClass]="{'delete-project-user-txt': data && data.from == 'PROVISION_TOOL'}">{{data.provisionMessage2 | translate}}</p>
      </li>
    </ul>

    <p class="status_inner" *ngFor="let item of data?.messageItems; let i=index"
    [ngClass]="{'user-pref-txt': data && ( data.from =='CREATE_PROJECT'),
    'status_inner_create': data && ( data.from =='CREATE_PROJECT'),
    'status_inner': data && ( data.from !='CREATE_PROJECT')
  }">
     <spam  *ngIf="data.from !='CREATE_PROJECT' && data.from !='PROVISION_TOOL'">{{ i+1}} . {{item}}</spam>
     <spam class="status_txt delete-project-user-txt" *ngIf="data.from =='PROVISION_TOOL'">{{ i+1}} . {{item}}</spam>
     <spam  *ngIf="data.from =='CREATE_PROJECT'">{{item}}</spam>
    </p>

    <p class="status_txt" [hidden]="data.from =='PROVISION_TOOL'" *ngFor="let error of data?.errors | keyvalue: originalOrder; let i = index">{{ error.value | translate }}</p>
    <p  *ngIf="data.from =='PROVISION_TOOL'">
      <spam class="status_txt delete-project-user-txt" *ngFor="let error of data?.errors | keyvalue: originalOrder; let i = index">{{ error.value | translate}}</spam>

    </p>

    <div *ngIf="(data?.title == 'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Title')">
      <h4>{{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step1' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.engagementStatus == 'Requested') ? 'requested' : (data.provisionSteps.engagementStatus=='Granted') ? 'granted' : (data.provisionSteps.engagementStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text" >{{data.provisionSteps.engagementStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.engagementStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step1.Error' | translate}}
      </span>
      </div>
      <h4>{{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step2' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.taxonomyStatus == 'Requested') ? 'requested' : (data.provisionSteps.taxonomyStatus == 'Granted') ? 'granted' : (data.provisionSteps.taxonomyStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text">{{data.provisionSteps.taxonomyStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.taxonomyStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step2.Error' | translate}}
      </span>
      </div>
      <ng-container *ngIf="data.provisionSteps.hasOwnProperty('techObjStatus')">
      <h4 >{{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step3' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.techObjStatus == 'Requested') ? 'requested' : (data.provisionSteps.techObjStatus == 'Granted') ? 'granted' : (data.provisionSteps.techObjStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text">{{data.provisionSteps.techObjStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.techObjStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.ECToolProvision.Step3.Error' | translate}}
      </span>
      </div>
     </ng-container>
    </div>

    <div *ngIf="(data?.title == Test_AI_PROVISION_TITLE)">
      <h4>{{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step1' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.projCreationStatus == 'Requested') ? 'requested' : (data.provisionSteps.projCreationStatus=='Granted') ? 'granted' : (data.provisionSteps.projCreationStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text" >{{data.provisionSteps.projCreationStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.projCreationStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step1.Error' | translate}}
      </span>
      </div>
      <h4>{{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step2' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.taxonomyStatus == 'Requested') ? 'requested' : (data.provisionSteps.taxonomyStatus == 'Granted') ? 'granted' : (data.provisionSteps.taxonomyStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text">{{data.provisionSteps.taxonomyStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.taxonomyStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step2.Error' | translate}}
      </span>
      </div>
      <h4 >{{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step3' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.userStoriesSyncStatus == 'Requested') ? 'requested' : (data.provisionSteps.userStoriesSyncStatus == 'Granted') ? 'granted' : (data.provisionSteps.userStoriesSyncStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text">{{data.provisionSteps.userStoriesSyncStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.userStoriesSyncStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>
        {{'Activities.ToolsProvision.DeliveryTools.UpdatingToTestAI.Step3.Error' | translate}}
      </span>
      </div>
    </div>

    <div *ngIf="(data?.title == EC_SYNC_INFO_POPUP?.title)">
      <h4>{{ EC_SYNC_INFO_POPUP?.step1 | translate}}</h4>
      <div class="tool-status" *ngIf="data?.provisionSteps?.attribute3" [ngClass]="data?.provisionSteps?.attribute3?.toLowerCase().trim()">
        <span class="status-provision-text" >{{EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute3?.toLowerCase().trim()]?.display_text}}</span>
      </div>
      <div *ngIf="EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute3?.toLowerCase()?.trim()] == 'Error'?.toLowerCase() || EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute3?.toLowerCase().trim()] == 'Failed'?.toLowerCase()" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>{{ EC_SYNC_INFO_POPUP?.step1_error | translate}}</span>
      </div>
      <h4>{{ EC_SYNC_INFO_POPUP?.step2 | translate }}</h4>
      <div class="tool-status" *ngIf="data?.provisionSteps?.attribute4" [ngClass]="data?.provisionSteps?.attribute4?.toLowerCase().trim()">
        <span class="status-provision-text">{{EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute4?.toLowerCase().trim()]?.display_text}}</span>
      </div>
      <div *ngIf="EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute4?.toLowerCase().trim()] == 'Error'?.toLowerCase() || EC_SYNC_STATUS_COLOR_CODE_MAP[data?.provisionSteps?.attribute4?.toLowerCase().trim()] == 'Failed'?.toLowerCase()" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>{{ EC_SYNC_INFO_POPUP?.step2_error | translate}}</span>
      </div>
    </div>

    <div *ngIf="(data?.title == 'Activities.ToolsProvision.DeliveryTools.SOToolProvision.Title')">
      <h4>{{'Activities.ToolsProvision.DeliveryTools.SOToolProvision.Step1' | translate}}</h4>
      <div class="tool-status" [ngClass]="(data.provisionSteps.engagementStatus == 'Requested') ? 'requested' : (data.provisionSteps.engagementStatus=='Granted') ? 'granted' : (data.provisionSteps.engagementStatus == 'Error') ? 'error' :''">
        <span class="status-provision-text" >{{data.provisionSteps.engagementStatus}}</span>
      </div>
      <div *ngIf="data.provisionSteps.engagementStatus == 'Error'" class="error-desc">
        <img src="../../../../assets/images/ErrorDescription.svg"><span>{{'Activities.ToolsProvision.DeliveryTools.SOToolProvision.Step1.Error' | translate}}</span>
      </div>
    </div>

    <div *ngIf="data?.content" class="afterrow"> <p class="status_txt three-column-layout" *ngFor="let sprint of data?.content ">{{ sprint.sprint_name + " to " + sprint.planned_capacity_percentage+"%" }}</p></div>
    <button mat-raised-button (click)="performAction()" *ngIf="data?.contentButtonText" class="btn-content">
      {{ data?.contentButtonText | translate}}
    </button>

    <!----------Auto reporting code begins------>

    <div class="download-report" *ngIf="data.from == 'DOWNLOAD_REPORTS'">
      <mat-label class="label-text">{{'Shared.CommonDialogBox.AutoReporting.Label.SelectReports' | translate}}</mat-label>
      <mat-radio-group (change)="validateReportInput()" [(ngModel)]="projectPackagesAndStatus.typeOfReports.currentSelected" class='radio-group-reports'>
        <mat-radio-button [disabled]="!typeReport.active" [checked]="projectPackagesAndStatus.typeOfReports.currentSelected == typeReport" *ngFor="let typeReport of projectPackagesAndStatus.typeOfReports.data" [value]="typeReport.name">{{typeReport.name}}</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="download-report" *ngIf="data.from == 'DOWNLOAD_REPORTS'" style="margin-top: 10px">
      <mat-label class="label-text">{{'Shared.CommonDialogBox.AutoReporting.Label.SelectOffering' | translate}}</mat-label>
      <br>
      <h4 class="sub-label-text label-whitespace">{{'Shared.CommonDialogBox.AutoReporting.Label.Offering' | translate}}</h4>
      <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
        <i class="fas fa-chevron-down"></i>
        <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
        <mat-select panelClass="auto-report-multiselect-dropdown" multiple [value]="projectPackagesAndStatus.currentPackage" name="selectOffering" class="placeholder-text" placeholder="Select Offering/Package" disableOptionCentering>
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip (removed)="removeChip(obj.name,'PACKAGE')" [hidden]="!obj.completed || i > 2" *ngFor="let obj of getOnlySelected('PACKAGE');let i=index" >
                  {{obj.name}}
                  <mat-icon class="close-icon" matChipRemove>close</mat-icon>
              </mat-chip>
                <h3 [matTooltip]="getTootipTextForExtra('PACKAGE')" matTooltipClass="mat-tooltip-extra-selection" *ngIf="getTotalSelected('PACKAGE')>3" class="more-plus">+{{getTotalSelected('PACKAGE')-3}}</h3>
            </mat-chip-list>
          </mat-select-trigger>

          <mat-option class="mat-opt-text" value="projectPackagesAndStatus.packages.allCompleted">
            <mat-checkbox [checked]="projectPackagesAndStatus.packages.allCompleted" [indeterminate]="checkSelectedStatus('PACKAGE')" (change)="selectAllStatus($event.checked,'PACKAGE')">{{'Global.Common.Label.SelectAll' | translate}}</mat-checkbox>
          </mat-option>
          <mat-option  class="mat-opt-text" *ngFor="let obj of projectPackagesAndStatus.packages.packageList; let i=index" [value]="obj.name">
            <mat-checkbox [(ngModel)]="obj.completed"  (ngModelChange)="updateAllCompleted('PACKAGE')">{{obj.name}}</mat-checkbox>
          </mat-option>
       </mat-select>
        </mat-form-field>
    </div>

    <div class="download-report" *ngIf="data.from == 'DOWNLOAD_REPORTS'">
      <mat-label class="label-text">{{'Shared.CommonDialogBox.AutoReporting.Label.SelectStatus' | translate}}</mat-label>
      <br>
      <h4 class="sub-label-text label-whitespace">{{'Shared.CommonDialogBox.AutoReporting.Label.ProjectStatus' | translate}}</h4>
      <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
        <i class="fas fa-chevron-down"></i>
        <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
        <mat-select panelClass="auto-report-multiselect-dropdown" multiple [value]="projectPackagesAndStatus.currentStatus" class="placeholder-text" placeholder="Select Project Status" disableOptionCentering>
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip (removed)="removeChip(obj.status,'STATUS')" [hidden]="!obj.completed || i > 2" *ngFor="let obj of getOnlySelected('STATUS');let i=index" >
                  {{obj.displayText}}
                  <mat-icon class="close-icon" matChipRemove>close</mat-icon>
              </mat-chip>
              <h3 [matTooltip]="getTootipTextForExtra('STATUS')" matTooltipClass="mat-tooltip-extra-selection" class="more-plus" *ngIf="getTotalSelected('STATUS')>3">+{{getTotalSelected('STATUS')-3}}</h3>
            </mat-chip-list>
          </mat-select-trigger>
          <mat-option class="mat-opt-text" value="projectPackagesAndStatus.statusOfProjects.allCompleted">
            <mat-checkbox [checked]="projectPackagesAndStatus.statusOfProjects.allCompleted" [indeterminate]="checkSelectedStatus('STATUS')" (change)="selectAllStatus($event.checked,'STATUS')">{{'Global.Common.Label.SelectAll' | translate}}</mat-checkbox>
          </mat-option>
          <mat-option  class="mat-opt-text" *ngFor="let obj of projectPackagesAndStatus.statusOfProjects.projectStatus; let i=index" [value]="obj.status">
            <mat-checkbox [(ngModel)]="obj.completed"  (ngModelChange)="updateAllCompleted('STATUS')">{{obj.displayText}}</mat-checkbox>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="download-report" *ngIf="data.from == 'DOWNLOAD_REPORTS'">
      <mat-label class="label-text">{{'Shared.CommonDialogBox.AutoReporting.Label.SpecifyDate' | translate }}</mat-label>
      <mat-radio-group (change)="validateReportInput()" [(ngModel)]='projectPackagesAndStatus.dateRangeOfReports.currentSelected' class='radio-group-reports' *ngFor="let dateType of projectPackagesAndStatus.dateRangeOfReports.data; let i = index">
      <div>
        <mat-radio-button matTooltipPosition="right" [matTooltip]="projectPackagesAndStatus.dateRangeOfReports.tooltips[i]" matTooltipClass="mat-tooltip-download-validation" [checked]="projectPackagesAndStatus.dateRangeOfReports.currentSelected == dateType" [value]="dateType">{{dateType}}</mat-radio-button>
      </div>
      </mat-radio-group>
    </div>

    <div class="download-report download-report-date form-group-wrapper" *ngIf="data.from == 'DOWNLOAD_REPORTS' && projectPackagesAndStatus.dateRangeOfReports.currentSelected == 'Custom date'">
           <!--start dtae-->
        <div class="form-group">
           <h4 class="sub-label-text">{{'Global.Common.Label.StartDate' | translate}}</h4>
      <mat-form-field appearance="outline" floatLabel="never" matTooltipPosition="above"
      matTooltipDisabled="false"
     >
      <!--Will be enabled when validation is required on start date-->
      <!-- <input disabled='true' [min]="projectPackagesAndStatus.startDate.minDate" [max]="projectPackagesAndStatus.startDate.maxDate" (dateChange)="changeDate('start')" [(ngModel)]="projectPackagesAndStatus.selectedStartDate" matInput [matDatepicker]="startPicker" placeholder="MM / DD / YYYY"
            > -->
      <input disabled='true' (dateChange)="changeDate('start')" [(ngModel)]="projectPackagesAndStatus.selectedStartDate" matInput [matDatepicker]="startPicker" placeholder="MM / DD / YYYY"
            >
      <mat-datepicker-toggle matSuffix [for]="startPicker">
        <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker disabled='false' #startPicker panelClass="download-report-datepicker"
        >
      </mat-datepicker>
    </mat-form-field>
  </div>
      <!--end date-->
      <div class="form-group">
      <h4 class="sub-label-text">{{'Global.Common.Label.EndDate' | translate}}</h4>
      <mat-form-field appearance="outline" floatLabel="never" matTooltipPosition="above"
      matTooltipDisabled="false"
     >
     <!--Will be enabled when validation is required on end date-->
      <!-- <input disabled='true' [min]="projectPackagesAndStatus.endDate.minDate" [max]="projectPackagesAndStatus.endDate.maxDate" (dateChange)="changeDate('end')" [(ngModel)]="projectPackagesAndStatus.selectedEndDate" matInput [matDatepicker]="endPicker" placeholder="MM / DD / YYYY"
            > -->
      <input disabled='true' (dateChange)="changeDate('end')" [(ngModel)]="projectPackagesAndStatus.selectedEndDate" matInput [matDatepicker]="endPicker" placeholder="MM / DD / YYYY"
            >
      <mat-datepicker-toggle matSuffix [for]="endPicker">
        <mat-icon matDatepickerToggleIcon>date_range</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker disabled="false" #endPicker panelClass="download-report-datepicker"
        >
      </mat-datepicker>
    </mat-form-field>
    </div>
    </div>
    <!--AUto reporting code ends-->

    <div class="select-option package-select" *ngIf="data.from =='USER_PREFERENCES'">
      <mat-label class="label-text">{{"Navigation.Menu.UserPreferences.Dialog.Form.Package" | translate}}</mat-label>
      <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
        <i class="fas fa-chevron-down"></i>
        <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
        <mat-select  name="selectOffering" class="placeholder-text" [placeholder]="'Navigation.Menu.UserPreferences.Dialog.Form.Package.Placeholder' | translate" disableOptionCentering
         [(ngModel)]="data.userPrefOffering"
         (selectionChange)="getPackages($event.value)"
         [disabled]="noOfferings">
         <mat-option class="mat-opt-text" *ngFor="let selectedOffering of data.selectedOffering" [value]="selectedOffering">{{selectedOffering}}
         </mat-option>
       </mat-select>
        </mat-form-field>
  </div>

   <div class="select-option" *ngIf="data.selectedPackage?.length && data.from =='USER_PREFERENCES'">
    <mat-label class="label-text">{{"Navigation.Menu.UserPreferences.Dialog.Form.Method" | translate}}</mat-label>
    <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
      <i class="fas fa-chevron-down"></i>
      <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
      <mat-select  name="selectedPackage" class="placeholder-text" [placeholder]="'Navigation.Menu.UserPreferences.Dialog.Form.Method.Placeholder' | translate" disableOptionCentering
       [(ngModel)]="data.userPrefPackage"
       (selectionChange)="getReleventFunctions($event.value)"
       [disabled]="noPublishedPackage">
       <mat-option class="mat-opt-text" *ngFor="let selectedPackage of data.selectedPackage" [value]="selectedPackage">{{selectedPackage}}
       </mat-option>
     </mat-select>
      </mat-form-field>
      <ng-container *ngIf="noPublishedPackage">
      <p style="font-size: 12px"> {{noPublishedPkgMsg}} </p>
    </ng-container>
</div>
<div class="select-option" *ngIf="data.from =='USER_PREFERENCES'">
  <mat-label class="label-text">{{"Navigation.Menu.UserPreferences.Dialog.Form.Function" | translate}}</mat-label>
  <mat-form-field class="form-field-option" appearance="outline" floatLabel="never">
    <i class="fas fa-chevron-down"></i>
    <img matSuffix src="../../../../assets/images/filter-pipe.png" width="1px" height="20px">
    <mat-select name="selectedFunctions" class="placeholder-text" [placeholder]="'Navigation.Menu.UserPreferences.Dialog.Form.Function.Placeholder' | translate" disableOptionCentering
     [(ngModel)]="data.userPrefFunction" [disabled]="noPublishedPackage || !data.selectedFunctions?.length>0" (selectionChange)="hideMessage($event.value)">
      <mat-option class="mat-opt-text" *ngFor="let selectedFunction of data.selectedFunctions" [value]="selectedFunction">{{selectedFunction}}
      </mat-option>
    </mat-select>
    </mat-form-field>
</div>

  </mat-dialog-content>
  <hr class="divider divider-bottom" *ngIf="data.from =='USER_PREFERENCES' || data.from =='CREATE_PROJECT' || data.from == 'DOWNLOAD_REPORTS'"/>
  <hr class="divider divider-bottom-delete" *ngIf=" data.from == 'PROJECT_USERS_DELETE' || data.from=='PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from =='DOWNLOAD_USER_STORY_SPRINT' || data.from == 'GENERATE_SPRINT' || data.from == 'DELETE_ALL_NOTIFICATIONS'"/>
  <hr class="divider divider-bottom-tool" *ngIf="data.from == 'PROVISION_TOOL'">
  <mat-dialog-actions align="end" class="custom-mat-dialog-actions" [ngStyle]="{'height': data.from == 'USER_PREFERENCES' ? '0%' : '100%'}"  *ngIf="data && data.from !='MARKETPLACE_SOLUTIONS'">
   <!-- Button - User Preferences -->
    <button *ngIf="(data.from == 'USER_PREFERENCES' && data?.cancelBtn == 'Navigation.Menu.UserPreferences.Dialog.Button.Cancel')" [disabled]="(!userPrefDataFound && !noPublishedPackage) " mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
      {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate }}
    </button>
    <button *ngIf="data.from == 'USER_PREFERENCES'" mat-raised-button [disabled]="!hideApplyBtn" (click)="apply(data.userPrefPackage, data.userPrefFunction, data.userPrefOffering)"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
      {{ (data?.buttonText ? data?.buttonText : 'CreateProject.Dialog.Client.Button.OK') | translate }}
    </button>
        <!-- Button - User Preferences -->
    <!--Button for download reports-->
    <button class="download-cancel-button" *ngIf="(data.from == 'DOWNLOAD_REPORTS' && data?.cancelBtn == 'Cancel')" [disabled]="false" mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
      {{ data?.cancelBtn ? data?.cancelBtn : 'OK' }}
    </button>

    <div [matTooltip]="projectPackagesAndStatus.currentValidationError" [matTooltipDisabled]="projectPackagesAndStatus.enableDownloadButton" matTooltipClass="mat-tooltip-download-validation">
    <button class="download-button" *ngIf="data.from == 'DOWNLOAD_REPORTS'" mat-raised-button [disabled]="!projectPackagesAndStatus.enableDownloadButton" (click)="downloadReportOnClick()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
      {{ data?.buttonText ? data?.buttonText : 'OK' }}
    </button>
    </div>
    <!---->


       <!-- Button - Create PRoject-->
       <button *ngIf="(data.from == 'CREATE_PROJECT' && data?.cancelBtn == 'CreateProject.ProjectDetails.Cancel')"  mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
       [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
         {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate }}
       </button>
       <button *ngIf="data.from == 'CREATE_PROJECT' && data.buttonContinueClass" mat-raised-button (click)="discard()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
       [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
         {{ (data?.buttonText ? data?.buttonText : 'CreateProject.Dialog.Client.Button.OK') | translate}}
       </button>
       <!-- Button - Create Projects -->
     <!-- Button - Delete Users -->
     <button *ngIf="(data.from == 'PROJECT_USERS_DELETE' && data?.cancelBtn == 'Global.Common.Button.Cancel')"  mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
     [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
       {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate}}
     </button>
     <button class="del-confirmbtn" *ngIf="(data.from == 'DELETE_ALL_NOTIFICATIONS' && data?.cancelBtn == 'Navigation.Menu.Notification.Dialog.Cancel')"  mat-raised-button (click)="closePopup()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
     [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
       {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate }}
     </button>
     <button *ngIf="data.from == 'PROJECT_USERS_DELETE'" mat-raised-button  (click)="deleteProjectUser(data.projectId, data.userId, data.deleteUserId)"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
     [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
       {{ (data?.buttonText ? data?.buttonText : 'CreateProject.Dialog.Client.Button.OK') | translate }}
     </button>
     <!-- Button - Delete Users -->

      <!-- Button - Delete Project -->
      <button *ngIf="(data.from == 'PROJECT_SOFT_DELETE' && data?.cancelBtn == 'CreateProject.ProjectDetails.Cancel')"  mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
        {{ (data?.cancelBtn ? data?.cancelBtn : 'CreateProject.Dialog.Client.Button.OK') | translate }}
      </button>
      <button *ngIf="data.from == 'PROJECT_SOFT_DELETE'" mat-raised-button  (click)="softDeleteProject(data.projectId, data.userId)"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
        {{ (data?.buttonText ? data?.buttonText : 'CreateProject.Dialog.Client.Button.OK') | translate}}
      </button>
      <!-- Button - Delete Project -->

      <button *ngIf="data.from == 'DOWNLOAD_USER_STORY_SPRINT'" mat-raised-button  (click)="close()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{'btn-continue btn-apply' : data.buttonContinueClass}">
      {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok' ) | translate}}
      </button>

      <button *ngIf="data.from == 'GENERATE_SPRINT'" mat-raised-button  (click)="close()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{'btn-continue btn-apply' : data.buttonContinueClass}">
      {{ data?.buttonText ? data?.buttonText : 'OK' }}
      </button>

      <button *ngIf="data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue' && data && data.from == 'PROVISION_TOOL' && data?.messageItems[0] != 'MS Teams' && data?.messageItems[0] != 'Test AI'" mat-raised-button (click)="data.continue(); closePopup();"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue provision' : data?.buttonContinueClass}">
        {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue' && data && data.from == 'PROVISION_TOOL' && data?.messageItems[0] == 'MS Teams'" mat-raised-button (click)=" closePopup();"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue provision' : data?.buttonContinueClass}">
        {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="!(data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue') && data && data.from == 'PROVISION_TOOL'  && data?.title != ecTitle && data?.title != soTitle && data?.title != testAITitle" mat-raised-button (click)="close()" cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue' : data?.buttonContinueClass, 'btn-cancel' : data?.buttonCancelClass}">
        {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="(data.from == 'PROVISION_TOOL' && data?.cancelBtn == 'Global.Common.Button.Cancel' && (data?.title == ecTitle || data?.title == soTitle || data?.title == testAITitle))"  mat-raised-button (click)="closePopup()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
        {{ (data?.cancelBtn ? data?.cancelBtn : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="(data.from == 'RETIRE_TAXONOMY' && data?.cancelBtn == 'Cancel')"  mat-raised-button (click)="closePopup()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
        {{ (data?.cancelBtn ? data?.cancelBtn : 'Global.Common.Button.Ok') | translate }}
      </button>

      <button *ngIf="(data?.title == ecTitle  || data?.title == soTitle || data?.title == testAITitle) && (data?.buttonText == 'Global.Common.Button.Confirm') && data && data.from == 'PROVISION_TOOL'" mat-raised-button (click)="close();" cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
      [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue provision' : data?.buttonContinueClass}">
        {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
      </button>

       <!-- Button - Delete Admin -->
       <button *ngIf="(data.from == 'ADMIN_DELETE' && data?.cancelBtn == 'Cancel')"  mat-raised-button (click)="close()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
       [ngClass]="{ 'btn-cancel' : data?.buttonCancelClass, 'btn-continue' : data?.buttonContinueClass}">
         {{ (data?.cancelBtn ? data?.cancelBtn : 'Global.Common.Button.Ok') | translate }}
       </button>
       <button *ngIf="data.from == 'ADMIN_DELETE'" mat-raised-button  (click)="deleteAdmin()"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
       [ngClass]="{'btn-continue btn-apply' : data?.buttonContinueClass}">
         {{( data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok' ) | translate}}
       </button>
       <!-- Button - Delete Admin -->

    <button *ngIf="(data.from == 'FinalizeSprint' && data?.cancelBtn == 'Cancel')" class="btn-cancel" mat-raised-button (click)="closePopupFinalizeSprint()"   [ngStyle]="{display: data?.display? data?.display:'block'}"
    >
      {{ (data?.cancelBtn ? data?.cancelBtn : 'Global.Common.Button.Ok') | translate }}
    </button>

    <button *ngIf="(data.from != 'DOWNLOAD_REPORTS') && !(data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue')" mat-raised-button (click)="close()" [hidden]="data.from== 'USER_PREFERENCES' || data.from == 'PROJECT_USERS_DELETE' || data.from == 'CREATE_PROJECT' || data.from == 'PROJECT_SOFT_DELETE' || data.from =='ADMIN_DELETE' || data.from =='DOWNLOAD_USER_STORY_SPRINT' || data.from =='GENERATE_SPRINT' || data.from =='PROVISION_TOOL'" cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{ 'btn-ok' : data?.btnokclass, 'btn-continue provision' : data?.buttonContinueClass}">
      {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate }}
    </button>

    <button *ngIf="data?.title == 'Provision Status' && data?.buttonText == 'Global.Common.Button.Continue'" [hidden]="data.from == 'PROVISION_TOOL'" mat-raised-button (click)="data.continue(); closePopup();"  cdkFocusInitial  [ngStyle]="{display: data?.display? data?.display:'block'}"
    [ngClass]="{ 'btn-ok error-style' : data?.btnokclass, 'btn-continue' : data?.buttonContinueClass}">
      {{ (data?.buttonText ? data?.buttonText : 'Global.Common.Button.Ok') | translate}}
    </button>
  </mat-dialog-actions>

  <div class="multicolor-band" [ngStyle]="{'position': data && data.from =='MARKETPLACE_SOLUTIONS' ? 'absolute' : ''}" [hidden]="data.from == 'USER_PREFERENCES' || data.from =='PROJECT_USERS_DELETE'  || data.from == 'CREATE_PROJECT' || data.from =='PROJECT_SOFT_DELETE' || data.from=='ADMIN_DELETE' || data.from =='DOWNLOAD_USER_STORY_SPRINT' || data.from =='PROVISION_TOOL' || data.from =='GENERATE_SPRINT' || data.from == 'RETIRE_TAXONOMY' || data.from == 'DELETE_ALL_NOTIFICATIONS' || data.from == 'DOWNLOAD_REPORTS'">
    <img class="rectangle" src="../../assets/bottom_color.png" />
  </div>
</div>
