import { EventEmitter, Injectable } from '@angular/core';
import { BaseService } from 'shared-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  response: '';
  public importFileRes: BehaviorSubject<any>;
  private activityListData: BehaviorSubject<any>;
  private selectedActivity: BehaviorSubject<any>;
  private isFullScreenMode : BehaviorSubject<boolean>;
  private searchBoxContent: BehaviorSubject<any>;
  private activityContext = new BehaviorSubject({});
  private enableCompleteStop: BehaviorSubject<boolean>;
  private isStopCompleted: BehaviorSubject<boolean>;
  private isDataSaved: BehaviorSubject<any>;
  private disciplineData: BehaviorSubject<any>;
  public userPermissionDataUpdated: BehaviorSubject<boolean>;
  public selectedPackage: any = this.cryptUtilService.getItem('SELECTED_PACKAGE','SESSION') ? this.cryptUtilService.getItem('SELECTED_PACKAGE','SESSION') : undefined;

  constructor(private baseService: BaseService, private http: HttpClient, public cryptUtilService: CryptUtilService,) {
    this.importFileRes = new BehaviorSubject(this.response);
    this.activityListData = new BehaviorSubject({});
    this.selectedActivity = new BehaviorSubject({});
    this.isFullScreenMode = new BehaviorSubject(false);
    this.enableCompleteStop = new BehaviorSubject(true);
    this.isStopCompleted = new BehaviorSubject(false);
    this.searchBoxContent = new BehaviorSubject(null);
    this.isDataSaved = new BehaviorSubject(null);
    this.disciplineData = new BehaviorSubject(null);
    this.userPermissionDataUpdated = new BehaviorSubject(false);
  }

  getActivityList(obj): Observable<any> {
    let discipline : string = ''

    let url: string = '';
    if(obj.projectId == 0){
      if(obj.subFunctionId){
        url = `/explore/activities?packageId=${obj.packageId}&functionId=${obj.functionId}&subFunctionId=${obj.subFunctionId}&stopId=${obj.stopId}&isExternalUser=${obj.isExternalUser}&languageId=${obj.languageId}`;
        if(obj?.filteredDisciplines?.length) {
         url += `&disciplineIds=[${obj?.filteredDisciplines}]`;
       }
        return this.baseService.get(url,environment.API_MICROSERVICE_URL?.METHOD);
      }
      else{
        url = `/explore/activities?packageId=${obj.packageId}&functionId=${obj.functionId}&stopId=${obj.stopId}&isExternalUser=${obj.isExternalUser}&languageId=${obj.languageId}`;
        if(obj?.filteredDisciplines?.length) {
          url += `&disciplineIds=[${obj?.filteredDisciplines}]`;
        }
        return this.baseService.get(url,environment.API_MICROSERVICE_URL?.METHOD);
      }
    }
    else{
      if(obj.subFunctionId){
        url = `/activities?projectId=${obj.projectId}&packageId=${obj.packageId}&functionId=${obj.functionId}&subFunctionId=${obj.subFunctionId}&stopId=${obj.stopId}&isExternalUser=${obj.isExternalUser}&languageId=${obj.languageId}`
        if(obj?.filteredDisciplines?.length) {
          url += `&disciplineIds=[${obj?.filteredDisciplines}]`;
        }
        return this.baseService.get(url,environment.API_MICROSERVICE_URL?.PROJECT);
      }
      else{
        url = `/activities?projectId=${obj.projectId}&packageId=${obj.packageId}&functionId=${obj.functionId}&subFunctionId=${obj.subFunctionId}&stopId=${obj.stopId}&isExternalUser=${obj.isExternalUser}&languageId=${obj.languageId}`
        if(obj?.filteredDisciplines?.length) {
          url += `&disciplineIds=[${obj?.filteredDisciplines}]`;
        }
        return this.baseService.get(url,environment.API_MICROSERVICE_URL?.PROJECT);
      }
    }
   }


  checkForConfirmFlags(projectId) : Observable<any> {
    return this.baseService.get(`/activities/tab-status?projectId=${projectId}`,environment.API_MICROSERVICE_URL?.PROJECT);
  }

  getNavigationStops(obj): Observable<any> {
    let url: string = '';

    if(obj.projectId == 0){
      url = `/explore/phase-stop/${obj.packageId}/${obj.stopId}/${obj.functionId}?languageId=${obj.languageId}`
      if (obj.disciplineIds) {
        url += `&disciplineIds=[${obj.disciplineIds}]`
      }
      return this.baseService.get(url, environment.API_MICROSERVICE_URL?.METHOD);
    }
    else{
      url = `/trainstop/phase_stop/${obj.projectId}/${obj.packageId}/${obj.stopId}/${obj.functionId}?languageId=${obj.languageId}`
      if (obj.disciplineIds) {
        url += `&disciplineIds=[${obj.disciplineIds}]`
      }
      return this.baseService.get(url, environment.API_MICROSERVICE_URL?.PROJECT);
    }
  }

  updateStop(param, data): Observable<any> {
    return this.baseService.put(`/trainstop/phase_stop/${param.projectId}`,data,environment.API_MICROSERVICE_URL?.PROJECT);
  }

  getActivitiesForFilteredFunctions(obj): Observable<any> {
      if(obj.projectId == 0)
        return this.baseService.get(`/explore/active-functions?functionId=${obj.functionId}&stopId=${obj.stopId}&projectId=${obj.projectId}`,environment.API_MICROSERVICE_URL?.METHOD)
      else
        return this.baseService.get(`/activities/active-functions?functionId=${obj.functionId}&stopId=${obj.stopId}&projectId=${obj.projectId}`,environment.API_MICROSERVICE_URL?.PROJECT)
  }

  getActivityListData(): Observable<any> {
    return this.activityListData.asObservable();
  }

  getSearchBoxContent(): Observable<any> {
    return this.searchBoxContent.asObservable();
  }

  setSearchBoxContent(data) {
    this.searchBoxContent.next(data);
  }

  setActivityListData(data) {
    this.activityListData.next(data);
  }

  getIsFullScreen(): Observable<boolean> {
    return this.isFullScreenMode.asObservable();
  }

  setIsFullScreen(data) {
    this.isFullScreenMode.next(data);
  }

  setActivityContext(data) {
    this.activityListData.next(data);
  }

  getEnableCompleteStop(): Observable<any> {
    return this.enableCompleteStop.asObservable();
  }

  setEnableCompleteStop(data) {
    this.enableCompleteStop.next(data);
  }

  getIsStopCompleted(): Observable<any> {
    return this.isStopCompleted.asObservable();
  }

  setIsStopCompleted(data) {
    this.isStopCompleted.next(data);
  }

  updateActivity(data): Observable<any> {
    return this.baseService.post(`/activities/save-activity`, data, environment.API_MICROSERVICE_URL.PROJECT);
  }

  setSelectedActivity(data) {
    this.selectedActivity.next(data);
  }

  getSelectedActivity(): Observable<any> {
    return this.selectedActivity.asObservable();
  }


  public hierarchyDetEvent = new EventEmitter();

  constructFilter(constructedFilter, filterObj, assigned) {

    if (filterObj.parentHierarchyId == null) {
      filterObj.children = [];
      constructedFilter.push(filterObj);
      return true;
    } else if (filterObj.parentHierarchyId == constructedFilter.hierarchyId && filterObj.dataVisible == 'N') {
      filterObj.children = [];
      constructedFilter.children.push(filterObj);
      return true;
    }
    else {
      if (constructedFilter.children != undefined) {
        for (let index = 0; index < constructedFilter.children.length; index++) {
          let constructedObj = constructedFilter.children[index];
          if (assigned == false) {
            assigned = this.constructFilter(constructedObj, filterObj, assigned);
          }
        }
      } else {
        for (let index = 0; index < constructedFilter.length; index++) {
          let constructedObj = constructedFilter[index];
          if (assigned == false) {
            assigned = this.constructFilter(constructedObj, filterObj, assigned);
          }
        }
      }
      return false;
    }
  }

  filterConstruct(filterData) {
    let constructedFilter = [];
    for (let i of filterData) {
      let filterObj = i;
      let assigned = false;
      this.constructFilter(constructedFilter, filterObj, assigned)
    }
    return constructedFilter;
  }

  setImportFilesData(res) {
    this.response = res;
    this.importFileRes.next(this.response);
  }

  getActivityDataSaved(): Observable<any> {
    return this.isDataSaved.asObservable();
  }

  setActivityDataSaved(data) {
    this.isDataSaved.next(data);
  }

  getDisciplineDataSaved(): Observable<any> {
    return this.disciplineData.asObservable();
  }

  setDisciplineDataSaved(data) {
    this.disciplineData.next(data);
  }

  downloadFile(downloadlink) {
    return this.http.get(`${downloadlink}`, {
     responseType: 'blob' as 'json',
     observe: 'response'
   }).subscribe(
     (data: any) => {
       console.log(data)
       const fileName = data.headers.get('content-disposition');
       const type = data.headers.get('content-type');
       const blob = new Blob([data.body], { type });
       saveAs(blob, fileName);
     },
     err => {
       console.log(err);
     });
  }

  getUserPermissionDataUpdated(): Observable<any> {
    return this.userPermissionDataUpdated.asObservable();
  }

  getExternalUserData(pageName?) {
    let externalUserData = this.cryptUtilService.getItem("external-user-data", "SESSION");
    return pageName ? {isExternalUser: externalUserData?.isExternalUser, pages : externalUserData?.pages[pageName] } : externalUserData;
  }

  getStopsData(languageId): Observable<any> {
    return this.baseService.get(`/package/stop-details?languageId=${languageId}`, environment.API_MICROSERVICE_URL.METHOD);
  }
}
