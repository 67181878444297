// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const APIVERSION = '/v1';
const API_GATEWAY_URL = '#{apiGatewayUrl}#';
const APIPREFIX='/api';
const APIVERSIONURL= APIPREFIX + APIVERSION;

export const environment = {
  production: '#{production}#',
  isLocal: false,
  SMART_PMO_ACCESS_URL: '#{smartPmoAccessUrl}#',
  BASE_URL: '#{baseUrl}#',

  API_MICROSERVICE_URL: {
    ADMIN : API_GATEWAY_URL + '/admin' + APIVERSIONURL,
    USER : API_GATEWAY_URL + '/user' + APIVERSIONURL,
    METHOD : API_GATEWAY_URL + '/Method' + APIVERSIONURL,
    PACKAGE_CONTENT : API_GATEWAY_URL + '/package-content' + APIVERSIONURL,
    PROJECT : API_GATEWAY_URL + '/Project' + APIVERSIONURL,
    NOTIFICATION : API_GATEWAY_URL + '/notification' + APIVERSIONURL,
    TEST_AI : API_GATEWAY_URL + '/TestAI' + APIVERSIONURL,
    CONTENT_MGMT : API_GATEWAY_URL + '/cms' + APIVERSIONURL,
    INTEGRATIONS : API_GATEWAY_URL + '/Integration' + APIVERSIONURL,
    MATURITY_ASSESSMENT : API_GATEWAY_URL + '/MA' + APIVERSIONURL,
    REPORTING : API_GATEWAY_URL + '/Reporting' + APIVERSIONURL,
    SOCKET_HUB: '#{hubMSBaseUrl}#' + '/hub/notification',
  },
  API_BASE_URL: '#{apiBaseUrl}#' + APIPREFIX,
  NOTIFICATION_API_BASE_URL: '#{notificationApiBaseUrl}#' + APIPREFIX,
  MA_API_BASE_URL: '#{maBaseUrl}#' + APIPREFIX,
  CMS_BASE_URL: '#{cmsBaseUrl}#' + APIPREFIX,
  JIRA_BASE_URL: '#{jiraBaseUrl}#',
  GEN_AI_URL: '#{genAIUrl}#',
  AUTONOMOUS_URL:'#{AutonomousUrl}#',
  AUTONOMOUS_CONFIG_URL:'#{AutonomousConfigUrl}#',
  ALM_BASE_URL: '#{almBaseUrl}#',
  AUTO_REPORTING_API_BASE_URL: '#{autoReportingBaseUrl}#'+ APIPREFIX,
  ALM_PROVISION_URL: '#{almProvisionUrl}#',
  EC_BASE_URL: '#{ecBaseUrl}#',
  SMART_PMO_URL:'#{smartPMOUrl}#',
  TEST_AI_URL: '#{testAIUrl}#',
  SMART_OFFERING_URL:'#{smartOfferingUrl}#',
  ASCEND_AI_API: '#{ascendAiApi}#' + APIPREFIX,
  COOKIE_CONSENT:'#{cookieConsent}#',
  AZURE_CONNECTION : '#{processApiUrl}#',
  APPLICATIONINSIGHTS_CONNECTION_STRING:'#{appInsightsConnectionString}#',
  ASCEND_GO_URL: '#{ascendGoUrl}#',
  ALLOWEDFILEEXTENSIONS: {
    UPLOAD: [ 'xls', 'xlsx', 'doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png', 'ppt', 'pptm', 'pptx', 'zip', 'rtf', 'mpp', 'vsdx', 'xdo', 'xdm', 'catalog', 'xlsm', 'vsd', 'txt', 'pages'],
    VIEW: ['xls', 'xlsx', 'doc', 'docx', 'pdf', 'ppt', 'pptm', 'pptx', 'txt']
  },
  ALLOWEDDOMAINS: ['deloitte', 'deloittece', 'deloittemx','tohmatsu'],
  ASCEND_ROADMAP_FILENAME: 'FY22-Ascend Roadmap.pptx',
  CMS_CONTAINER_NAMES: {
    DELIVERABLES: 'use-azs-cms-ascend',
    UTILITY: 'use-azs-utility-ascend',
    PROJECT_WORKSPACE: 'use-azs-project-workspace-ascend'
  },
  AUTH_CONFIG: {
    clientID: '#{clientID}#',
    authority: "#{authority}#",
    redirectUri: '#{redirectUri}#',
    cacheLocation: '#{cacheLocation}#',
    postLogoutRedirectUri: "#{postLogoutRedirectUri}#",
    navigateToLoginRequestUrl: false,
    consentScopes: ['#{consentScopes}#', "user.read", "offline_access"],
    unprotectedResources: ["https://www.microsoft.com/en-us/"],
    logger (logLevel, message, piiEnabled){
      return undefined;
    },
    correlationId: '1234',
    piiLoggingEnabled: true
  },
  AUTH_RESOURCE_LINK: {
    self: "https://graph.microsoft.com/v1.0/me",
    users: "https://graph.microsoft.com/v1.0/users",
    webAPI: '#{webAPI}#'
  },

  LOCAL_AUTH: {
    userId: '',
    username: '',
    ssoUser: {
      displayName: '',
      givenName: '',
      jobTitle: '',
      surname: '',
    }
  },
  LOCAL_USERS: [],

  // End

  DESIGNATIONS_LIST: [
    'DC Intern',
    'DC Analyst',
    'DC Product Manager',
    'DC Consultant',
    'DC Senior Consultant',
    'DC Manager',
    'DC Specialist Master',
    'DC Senior Manager',
    'DC Specialist Leader',
    'Managing Director, Consulting',
    'Managing Director',
    'DC Principal',
    'DC Partner',
    'Partner',
    'Principal',
    'External',
    'DC Solution Specialist',
    'DC Solution Analyst',
    'DC Specialist Senior'
  ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
