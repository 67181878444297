import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {

  public filteredData = new Subject<any>();
  public projectInfo = new Subject<any>();
  public filters = new Subject<any>();
  private sitemapURLProject: string = `${environment.API_MICROSERVICE_URL.PROJECT}/`;
  private sitemapFilterURLProject: string = `${environment.API_MICROSERVICE_URL.PROJECT}/`;
  private sitemapURLMethod: string = `${environment.API_MICROSERVICE_URL.METHOD}/`;
  private sitemapFilterURLMethod: string = `${environment.API_MICROSERVICE_URL.METHOD}/`;
  public setIsSitemapExplore = new Subject();
  public data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public exploreData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) { }

  getSitemapFiltersData(projectId): Observable<any> {
    if (!+projectId) {
      return this.http.get<any>(this.sitemapFilterURLMethod + 'explore/sitemap-details/filters/' + "?ampdel=1&attributes=1&source=sitemap");
    } else {
      return this.http.get<any>(this.sitemapFilterURLProject + 'sitemap-details/filters/' + projectId + "?ampdel=1&attributes=1&source=sitemap");
    }
  }

  getSitemapPageData(projectId: string | number = null, packageId: number = null, functionId: number = null, isSAP: boolean = false, languageId: number = null): Observable<any> {
    if (+projectId === 0) {
      return this.http.get<any>(this.sitemapURLMethod + 'explore/sitemap-details/' + "?packageId=" + packageId + "&functionId=" + functionId + "&isSAP=" + isSAP + "&languageId=" + languageId);
    } else {
      return this.http.get<any>(this.sitemapURLProject + 'sitemap-details/sitemap-details' + "?projectId=" + projectId + "&packageId=" + packageId + "&functionId=" + functionId + "&isSAP=" + isSAP + "&languageId=" + languageId);
    }
  }


  getProjectInfo(projectId: any) {
    return this.http.get(this.sitemapURLProject + 'project/' + projectId);
  }

  setFilteredData(data) {
    this.filteredData.next(data);
  }
  setProjectInfo(data) {
    this.projectInfo.next(data);
  }
  setFilters(data) {
    this.filters.next(data);
  }

  getData(dataVal: any): void {
    this.data.next(dataVal);
  }
  setData(): Observable<any> {
    return this.data.asObservable();
  }
  getAttributeFilter(dataVal: any): void {
    this.data.next(dataVal);
  }
  setAttributeFilter(): Observable<any> {
    return this.data.asObservable();
  }
  getExploreData(dataVal: any): void {
    this.exploreData.next(dataVal);
  }
  setExploreData(): Observable<any> {
    return this.exploreData.asObservable();
  }
}
