import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { environment } from 'src/environments/environment';
import { BaseService } from 'shared-lib';
@Injectable({
  providedIn: 'root'
})
export class AdminManagementService {

  BASE_URL = '';
  private userPreferenceURL: string = `${environment.API_MICROSERVICE_URL?.USER}/user-preferences`
  private allUserAdminsURL: string = `${environment.API_MICROSERVICE_URL?.USER}/user-management/admins`

  constructor(private http: HttpClient,private baseService: BaseService) {
    this.BASE_URL = environment.API_BASE_URL;
  }

  getUserPreferences(): Observable<any> {
    return this.http.get<any>(this.userPreferenceURL + '/packages');
  }

  getAllUserAdmins(langId, filters?): Observable<any> {
    return this.http.get<any>(`${this.allUserAdminsURL}?languageId=${langId}`, { params: filters });
  }

  getUser(userId,langId, fromScreen?) {
    let apiEndPoint = fromScreen ? 'user-management/userRoleDetails' : 'user-management';
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.USER + `/${apiEndPoint}/` + userId + `?languageId=${langId}`);
  }

  getAllAdminEmails(): Observable<any> {
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.USER + '/user-management/admin-emails');
  }

  activateDeactivateUser(requestPayload): Observable<any> {
    return this.http.put<any>(this.allUserAdminsURL, requestPayload );

  }

  saveUsers(users) {
    return this.http.post<any>(this.allUserAdminsURL, users);
  }

  getContactList(userId){
    return this.baseService.get(`/user/admin/contacts/${userId}`, environment.API_MICROSERVICE_URL?.USER);
 }
  getElevatedUserDetails() {     
    return this.http.get<any>(environment.API_MICROSERVICE_URL?.USER + '/user-management/elevatedUserDetails');
  }
}
