import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { element } from 'protractor';
import { Subscription } from "rxjs";
import { find } from 'rxjs-compat/operator/find';
import { BUS_MESSAGE_KEY } from "src/app/shared/constants/message-bus";
import {
  FilterData,
  FILTER_CUSTOM_CONSTANTS,
} from "src/app/shared/model/filter-content.model";
import { ProjectGlobalInfoModel } from "src/app/shared/model/project-global-info.model";
import { ActivateTabDataService } from 'src/app/shared/services/activate/activate-tab-data.service';
import { CryptUtilService } from "src/app/shared/services/crypt-util.service";
import { FilterCustomService } from "src/app/shared/services/filter-custom.service";
import { PassGlobalInfoService } from "src/app/shared/services/pass-project-global-info.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';

interface Trainstop {
  deliverables: Deliverable[],
  amplifiers: Amplifier[],
  sequence: number,
  trainstop_id: number,
  trainstop_name: string
}

interface Deliverable {
  name: string,
  deliverable: boolean,
  amplifier: boolean,
  attributes: Attribute[],
  is_display?: boolean
}
interface Amplifier {
  name: string,
  deliverable: boolean,
  amplifier: boolean,
  attributes: Attribute[],
  is_display?: boolean
}
interface Attribute {
  method_id: number,
  method_name: string,
  method_icon_name: string,
  is_applicable: boolean,
  show_icon: boolean
}

@Component({
  selector: "app-site-map",
  templateUrl: "./site-map.component.html",
  styleUrls: ["./site-map.component.scss"],
})
export class SiteMapComponent implements OnInit {
  @Input() view: String;
  @Input() projectName: any;
  @Input() clientName: any;
  @Input() clientLogoURL: any;

  projectGlobalInfo: ProjectGlobalInfoModel;

  filterURL = "/iidrfilter/";
  filters: FilterData[] = [];
  storageConstant: string;
  filterLoaded = false;
  tabName = "IIDR";
  subscription: Subscription;
  urlTrailer: string = "";
  breadPackage: any = "";
  breadFunction: any = "";
  prepare = [];
  imagine = [];
  deliver = [];
  run = [];
  functionFilteredData: FilterData;
  functionId: any = "";
  packageId: any = "";
  showPhaseZero: boolean;
  showWaterfall: boolean;
  showAgile: boolean;
  showAmplifiers: boolean = true;
  showDeliverables: boolean = true;
  searchAPIData: any;
  userData :any[]=[];
  packageData:any[]=[];
  functionData:any[]=[];
  filterDefaultData:any;
  sessionFilterData:any;
  projectModeData:any;
  masterTrainStops: any =[
      {​
        "phase_name": "Prepare",
        "seq_id": 1,
        "phaseID": 9004,
        "trainstops": [
          {​
            "trainstop_name": "Sense",
            "trainstop_id": 8016,
            "sequence": 1,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Define digital ambitions",
            "trainstop_id": 8023,
            "sequence": 2,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Define journey",
            "trainstop_id": 8019,
            "sequence": 3,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Establish value case",
            "trainstop_id": 8020,
            "sequence": 4,
            "deliverables": null,
            "amplifiers": null
          }​
        ]
      }​,
      {​
        "phase_name": "Imagine",
        "seq_id": 2,
        "phaseID": 9001,
        "trainstops": [
          {​
            "trainstop_name": "Launch journey",
            "trainstop_id": 8007,
            "sequence": 1,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Define operate organization",
            "trainstop_id": 8005,
            "sequence": 2,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Apply design thinking",
            "trainstop_id": 8002,
            "sequence": 3,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Adapt leading practices",
            "trainstop_id": 8001,
            "sequence": 4,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Refine user stories",
            "trainstop_id": 8009,
            "sequence": 5,
            "deliverables": null,
            "amplifiers": null
          }​
        ]
      }​,
      {​
        "phase_name": "Deliver",
        "seq_id": 3,
        "phaseID": 9000,
        "trainstops": [
          {​
            "trainstop_name": "Activate operate organization",
            "trainstop_id": 8012,
            "sequence": 1,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Sprint",
            "trainstop_id": 8003,
            "sequence": 2,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Validate",
            "trainstop_id": 8011,
            "sequence": 3,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Deploy",
            "trainstop_id": 8006,
            "sequence": 4,
            "deliverables": null,
            "amplifiers": null
          }​
        ]
      }​,
      {​
        "phase_name": "Run",
        "seq_id": 4,
        "phaseID": 9002,
        "trainstops": [
          {​
            "trainstop_name": "Initiate",
            "trainstop_id": 8024,
            "sequence": 1,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Transition",
            "trainstop_id": 8010,
            "sequence": 2,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Stabilize and optimize",
            "trainstop_id": 8008,
            "sequence": 3,
            "deliverables": null,
            "amplifiers": null
          }​,
          {​
            "trainstop_name": "Sustain and innovate",
            "trainstop_id": 8004,
            "sequence": 4,
            "deliverables": null,
            "amplifiers": null
          }​
        ]
      }​
    ];
  attributesFilterMetadata: any= [
    {
      "entityId": 1,
      "entityType": "attributes",
      "entityName": "Phase 0",
      "selectedFlag": "N"
  },
  {
    "entityId": 2,
    "entityType": "attributes",
    "entityName": "Waterfall",
    "selectedFlag": "N"
  },
  {
    "entityId": 3,
    "entityType": "attributes",
    "entityName": "Agile",
    "selectedFlag": "N"
  }];
    constructor(
    private router : Router,
    private sanitizer: DomSanitizer,
    private filterCustomService: FilterCustomService,
    private cryptUtilService: CryptUtilService,
    public sharedService: SharedService,
    private globalData: PassGlobalInfoService,
    private activateTabDataService: ActivateTabDataService,
    private userPreferenceService : UserPreferenceService) {
      this.userPreferenceService.subjectData.subscribe(userId=>{
        this.userData.push(userId);
      });
     


    }

  getSafeURL(logoURL) {
    return logoURL;
  }

  ngOnInit() {
    this.subscription = this.globalData.share.subscribe((data) => {
      this.view = data.viewMode;
      this.clientName = data.clientName;
      this.projectName = data.projectName;
      this.clientLogoURL = data.clientUrl;
      this.projectGlobalInfo = data;
      this.projectGlobalInfo.projectId = this.projectGlobalInfo.projectId
        ? this.projectGlobalInfo.projectId
        : "0";
      this.storageConstant =
        FILTER_CUSTOM_CONSTANTS.SITEMAP_FILTER +
        "_" +
        this.projectGlobalInfo.projectId;
      this.emitFilter(null);
      // this.getSiteMapData();
    });
    this.userPreferenceService.sendProjectId(this.projectGlobalInfo.projectId);
    if(this.projectGlobalInfo.projectId == '0') {
      this.sharedService.selectedPackage = {
        entityName:'Oracle ERP Cloud'
      }
    }
    if(this.cryptUtilService.getItem('SELECTED_PACKAGE','SESSION') && this.sharedService.selectedPackage && !this.sharedService.isSitemapPackageReset) {
      if(this.sharedService.selectedPackage.entityName.toLowerCase() != this.cryptUtilService.getItem('SELECTED_PACKAGE','SESSION').entityName.toLowerCase()) {
        this.sharedService.selectedPackage = this.cryptUtilService.getItem('SELECTED_PACKAGE','SESSION');
      }
    }
    else if(this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityId) {
      this.cryptUtilService.setItem('SELECTED_PACKAGE',this.sharedService.selectedPackage,'SESSION')
    }
   // this.defaultFunctionSelected();
  }

  defaultFunctionSelected(){
    this.userPreferenceService.getPackageData().subscribe((response) => {
      if(response){
        if(this.projectGlobalInfo.projectId == '0') {
          this.sharedService.selectedPackage = {
            entityName: response
          }
          if(this.cryptUtilService.getItem('SELECTED_PACKAGE','SESSION') && this.sharedService.selectedPackage && !this.sharedService.isSitemapPackageReset) {
            if(this.sharedService.selectedPackage.entityName.toLowerCase() != this.cryptUtilService.getItem('SELECTED_PACKAGE','SESSION').entityName.toLowerCase()) {
              this.sharedService.selectedPackage = this.cryptUtilService.getItem('SELECTED_PACKAGE','SESSION');
            }
          }
          else if(this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityId) {
            this.cryptUtilService.setItem('SELECTED_PACKAGE',this.sharedService.selectedPackage,'SESSION')
          }
        }
      }
    });


  }

  emitFilter(e) {
    if (e) {


      this.filterCustomService.updateFilters(
        this.filters,
        e,
        this.storageConstant
      );


      this.urlTrailer = this.filterCustomService.formURL(this.filters);
      this.cryptUtilService.setItem(
        BUS_MESSAGE_KEY.SITEMAP_FILTER +
          "_functionpackage_" +
          this.projectGlobalInfo.projectId,
        this.urlTrailer,
        "SESSION"
      );
      this.cryptUtilService.setItem("Previous-Sitemap-Filter",this.filters,"SESSION");

      this.sharedService.filterSelected = this.filterCustomService.checkFilterSelected(
        this.filters
      );
      // this.messagingService.publish(BUS_MESSAGE_KEY.IIDR_FILTER, urlTrailer)
      this.breadcrumbsSelectFunction(this.filters);
    } else {
      this.filterCustomService
        .getFilterData(
          this.filterURL +
            this.projectGlobalInfo.projectId +
            "?ampdel=1&attributes=1&source=sitemap",
          this.storageConstant,
          this.projectGlobalInfo.projectId,
          this.sharedService.isSitemapPackageReset
        )
        .subscribe((data) => {
          this.sessionFilterData = this.cryptUtilService.getItem("Previous-Sitemap-Filter","SESSION");

          if (!this.filterCustomService.isPackageOrFunctionSelected(data)) {
            data.map((element) => {
              for (let data of element.l1Filter.filterValues[0].childValues) {
                if (
                  data.entityName == "Phase 0" ||
                  data.entityName == "Oracle ERP Cloud" ||
                  data.entityName == "Core" ||
                  data.entityName == "Amplifiers" ||
                  data.entityName == "Deliverables"
                ) {
                  data.selectedFlag = "Y";
                  if (element.title == "Packages") {
                    this.breadPackage = data.entityName;
                    this.packageId = data.entityId;
                  }
                  if (element.title == "Functions") {
                    this.breadFunction = data.entityName;
                    this.functionId = data.entityId;
                  }
                } else {
                  data.selectedFlag = "N";
                }

                if (data.entityName == "Oracle ERP Cloud") {
                  this.cryptUtilService.setItem(
                    "SELECTED_PACKAGE",
                    data,
                    "SESSION"
                  );
                }
              }
              this.filters.push(element);
            });
            this.getSiteMapData();
          } else {
            if (
              this.projectGlobalInfo.projectId &&
              this.projectGlobalInfo.projectId != "0"
            ) {
              //Setting session and shared object for project mode.

                this.cryptUtilService.setItem("SELECTED_PACKAGE", this.filterCustomService.getSelectedPackage(data), "SESSION" );
                this.sharedService.selectedPackage = this.filterCustomService.getSelectedPackage(data);

            }

              this.userPreferenceService.getPackageData().subscribe((response) => {
                if(response){
                  if(this.projectGlobalInfo.projectId == '0') {
                    if(data){
                      data.forEach((ele)=>{
                        if(ele.title == "Packages"){
                          ele.l1Filter.filterValues.forEach((element)=>{
                            element.childValues.forEach((child)=>{
                              if(response.toLowerCase() == child.entityName.toLowerCase())
                              {
                                child.selectedFlag = "Y";
                              }
                              else{
                                child.selectedFlag = "N";
                              }
                            });
                          });
                        }
                      });
                    }
                  }
                }
                this.userPreferenceService.getFunctionData().subscribe((responseData) => {
                  if(responseData){
                    if(this.projectGlobalInfo.projectId == '0') {
                      if(data){
                        data.forEach((ele)=>{
                          if(ele.title == "Functions"){
                            ele.l1Filter.filterValues.forEach((element)=>{
                              element.childValues.forEach((child)=>{
                                if(responseData.toLowerCase() == child.entityName.toLowerCase())
                                {
                                  child.selectedFlag = "Y";
                                }
                                else
                                {
                                  child.selectedFlag = "N";
                                }
                              });
                            });
                          }
                        });
                      }
                    }
                  }
                  this.cryptUtilService.setItem("SELECTED_PACKAGE", this.filterCustomService.getSelectedPackage(data), "SESSION" );
                  this.sharedService.selectedPackage = this.filterCustomService.getSelectedPackage(data);



                 if(this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName == 'SAP ERP' && this.projectGlobalInfo.projectId == '0')
                {
                  this.filters =
                  data.map(item => {
                    if(item.title == 'Packages') {
                      item.l1Filter.filterValues[0].childValues.forEach(x => {
                        this.userPreferenceService.getPackageData().subscribe((response) => {
                          if(response){
                          x.selectedFlag = x.entityName.toLowerCase() == response.toLowerCase() ? 'Y' : 'N';
                          }
                        });
                      // x.selectedFlag = x.entityName.toLowerCase() == this.sharedService.selectedPackage.entityName.toLowerCase() ? 'Y' : 'N';
                      })

                      return item;
                    } else {
                    if(item.title == 'Functions') {
                      item = this.filterCustomService.filterFuntionsByPackageId(data);
                        item && item.l1Filter.filterValues[0].childValues.forEach(x => {
                          this.userPreferenceService.getFunctionData().subscribe((response) => {
                            if(response){
                            x.selectedFlag = x.entityName.toLowerCase() == response.toLowerCase() ? 'Y' : 'N';
                            }
                          });
                        //  x.selectedFlag = x.entityName.toLowerCase() == 'Project Management'.toLowerCase() ? 'Y' : 'N';

                        })

                      this.filterCustomService.setFunctionsFilterSelectState(item,'SITEMAP_OG_FILTERDATA');
                        return item;
                      } else {
                        return item;
                      }
                    }
                  });

                  this.breadcrumbsSelectFunction(this.filters);

                }
                else if(this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName != 'SAP ERP' && this.projectGlobalInfo.projectId == '0') {
                  this.filters = data.map(item => {

                    if(item.title == 'Functions') {
                      item = this.filterCustomService.filterFuntionsByPackageId(data);
                      this.filterCustomService.setFunctionsFilterSelectState(item,'SITEMAP_OG_FILTERDATA');
                        return item;
                      } else {
                        return item;
                      }

                  });



                  if(this.sessionFilterData){
                    this.breadcrumbsSelectFunction(this.sessionFilterData);
                  }
                  else{
                    this.breadcrumbsSelectFunction(this.filters);
                    this.getSiteMapData();
                  }

                } else {
                  // Project mode
                  this.filters = data;
                  this.breadcrumbsSelectFunction(this.filters);
                  this.getSiteMapData();
                }

                if(this.sessionFilterData){
              this.getSiteMapData();
                }
              });
              });



            if(this.sessionFilterData){


                    this.cryptUtilService.setItem("SELECTED_PACKAGE", this.filterCustomService.getSelectedPackage(this.sessionFilterData), "SESSION" );
                this.sharedService.selectedPackage = this.filterCustomService.getSelectedPackage(this.sessionFilterData);



                 if(this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName == 'SAP ERP' && this.projectGlobalInfo.projectId == '0')
                {
                  this.filters =
                  this.sessionFilterData.map(item => {
                    if(item.title == 'Packages') {
                      item.l1Filter.filterValues[0].childValues.forEach(x => {

                       x.selectedFlag = x.entityName.toLowerCase() == this.sharedService.selectedPackage.entityName.toLowerCase() ? 'Y' : 'N';
                      })

                      return item;
                    } else {
                    if(item.title == 'Functions') {
                      item = this.filterCustomService.filterFuntionsByPackageId(this.sessionFilterData);


                      this.filterCustomService.setFunctionsFilterSelectState(item,'SITEMAP_OG_FILTERDATA');
                        return item;
                      } else {
                        return item;
                      }
                    }
                  });

                  this.breadcrumbsSelectFunction(this.filters);

                }
                else if(this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName != 'SAP ERP' && this.projectGlobalInfo.projectId == '0') {
                  this.filters = this.sessionFilterData.map(item => {

                    if(item.title == 'Functions') {
                      item = this.filterCustomService.filterFuntionsByPackageId(this.sessionFilterData);
                      this.filterCustomService.setFunctionsFilterSelectState(item,'SITEMAP_OG_FILTERDATA');
                        return item;
                      } else {
                        return item;
                      }

                  });


                  this.breadcrumbsSelectFunction(this.filters);

                }
                this.getSiteMapData();


            }


               if(this.projectGlobalInfo.projectId != '0'){
                // Project mode
                this.filters = data;
                this.breadcrumbsSelectFunction(this.filters);
              }
            this.getSiteMapData();
          }

          //Baxter Project Package change
          if (
            this.filters &&
            this.projectName.toLowerCase() ==
              "baxter jde next generation erp program"
          ) {
            this.filters[0].l1Filter.filterValues[0].childValues[0].entityName =
              "JD Edwards";
          }

          this.filterLoaded = true;
          this.urlTrailer = this.filterCustomService.formURL(this.filters);
          this.cryptUtilService.setItem(
            BUS_MESSAGE_KEY.SITEMAP_FILTER +
              "_functionpackage_" +
              this.projectGlobalInfo.projectId,
            this.urlTrailer,
            "SESSION"
          );


          this.sharedService.filterSelected = this.filterCustomService.isPackageOrFunctionSelected(
            this.filters
          );

        });
    }


    if (e && e.title == "Packages") {
        this.prepare = [];
        this.imagine = [];
        this.deliver = [];
        this.run = [];
      if (
        e.l1Filter.filterValues[0].childValues.find(
          (e) => e.selectedFlag == "Y"
        )
      ) {
        this.breadFunction = "";
      } else {
        this.breadFunction = "";
        this.breadPackage = "";
        this.packageId = "";
      }
    }
    if (e && e.title == "Functions") {
      if (
        e.l1Filter.filterValues[0].childValues.find(
          (e) => e.selectedFlag == "Y"
        )
      ) {
        this.getSiteMapData();
      } else {
        this.breadFunction = "";
        this.functionId = "";
        this.prepare = [];
        this.imagine = [];
        this.deliver = [];
        this.run = [];
      }
    }
    if (e && e.title == "Attributes") {
      this.filterAttributes(e);
    }
    if (e && e.title == "Amplifiers / Deliverables") {
      this.filterAmpDel(e);
    }
    this.urlTrailer = this.filterCustomService.formURL(this.filters);
          this.cryptUtilService.setItem(
            BUS_MESSAGE_KEY.SITEMAP_FILTER +
              "_functionpackage_" +
              this.projectGlobalInfo.projectId,
            this.urlTrailer,
            "SESSION"
          );

  }



  resetAttributesFilter() {
    this.attributesFilterMetadata.map(ele => {
      ele.selectedFlag = 'Y';
    })
   let  originalFilterData = this.cryptUtilService.getItem('SITEMAP_OG_FILTERDATA', 'SESSION');
   if(originalFilterData) {
   originalFilterData.forEach(data => {

      if (data.title === "Attributes") {
        data.l1Filter.filterValues[0].childValues.map(element => {
          element.selectedFlag = 'Y';
        });
      }
    });
    this.filterCustomService.updateSessionStorage(originalFilterData,'SITEMAP_OG_FILTERDATA');
    }
  }

  filterAttributes(element) {
    for (let data of element.l1Filter.filterValues[0].childValues) {
      if (data.selectedFlag == "Y") {
        this.attributesFilterMetadata.find(ele => ele.entityId == data.entityId).selectedFlag = 'Y';
      }
      if (data.selectedFlag == "N") {
        this.attributesFilterMetadata.find(ele => ele.entityId == data.entityId).selectedFlag = 'N';
      }
    }
    if(this.searchAPIData) {
        this.manageSitemapDataByPhase(this.searchAPIData);
    }
  }

  /**
   * To allow setting the flags for filtering deliverables based on user selection
   * @param trainstops
   */
  displayDeliverables(trainstops: any): any {
    if(this.sharedService.selectedPackage && this.sharedService.selectedPackage.entityName == 'SAP ERP') {
      if(trainstops) {
        trainstops.forEach(trainstop => {
          if(trainstop.deliverables || trainstop.amplifiers) {
            trainstop = this.manageDeliverables(trainstop);
          }
        });
      }
    }
    return trainstops;
  }

  /**
   * To decide whether user has clicked on select all/unselect all or any custom option(s)
   * @param trainstop
   */
  manageDeliverables(trainstop: Trainstop): Trainstop {
    if ((this.attributesFilterMetadata.filter(x => x.selectedFlag == 'Y').length == this.attributesFilterMetadata.length) ||
      (this.attributesFilterMetadata.filter(x => x.selectedFlag == 'N').length == this.attributesFilterMetadata.length)) {
        return this.setAllDeliverablesAndAttributes(trainstop);
    } else
     {
      return this.setDeliverablesAndAttributesBySelectedAttributes(trainstop);
     }
  }

  /**
   * To set required flags i.e. is_display of deliverable and show_icon of attribute's icon to display result of Select all/ Unselect all
   * @param trainstop
   */
  setAllDeliverablesAndAttributes(trainstop: Trainstop): Trainstop {
    if(trainstop.deliverables) {
      trainstop.deliverables.forEach(element => {
        element.is_display = true;
        element.attributes.forEach(attribute => {
          if(attribute.is_applicable) {
            attribute.show_icon = true;
          } else {
            attribute.show_icon = false;
          }
        })
      });
    }
    if(trainstop.amplifiers) {
      trainstop.amplifiers.forEach(element => {
        element.is_display = true;
        element.attributes.forEach(attribute => {
          if(attribute.is_applicable) {
            attribute.show_icon = true;
          } else {
            attribute.show_icon = false;
          }
        })
      });
    }
    return trainstop;
  }

  /**
   * To set required flags i.e. is_display of deliverable and show_icon of attribute's icon to display result of filter input provided
   * @param trainstop
   */
  setDeliverablesAndAttributesBySelectedAttributes(trainstop: Trainstop): Trainstop {

    let userSelectedAttributes = this.attributesFilterMetadata.filter(x => x.selectedFlag == 'Y');
    let userUnselectedAttributes = this.attributesFilterMetadata.filter(x => x.selectedFlag == 'N');
    if(trainstop.deliverables) {
      trainstop.deliverables.forEach(item => {
      if(item.attributes) {
       item.is_display = false;
        userSelectedAttributes.find(x => {
            item.attributes.filter(z => {
                    if(z.is_applicable) {
                        if(z.method_name.toLowerCase() == x.entityName.toLowerCase()){
                            z.show_icon = true
                            item.is_display = true;
                          }

                    }

              });
        });

        userUnselectedAttributes.find(x => {
          item.attributes.filter(z => {
            if(z.method_name.toLowerCase() == x.entityName.toLowerCase()) {
              z.show_icon = false;
            }
          });
        });

        }
        else {
          item.is_display = false;
        }
    });
    }
    if(trainstop.amplifiers) {
      trainstop.amplifiers.forEach(item => {
      if(item.attributes) {
       item.is_display = false;
        userSelectedAttributes.find(x => {
            item.attributes.filter(z => {
                    if(z.is_applicable) {
                        if(z.method_name.toLowerCase() == x.entityName.toLowerCase()){
                            z.show_icon = true
                            item.is_display = true;
                          }

                    }

              });
        });

        userUnselectedAttributes.find(x => {
          item.attributes.filter(z => {
            if(z.method_name.toLowerCase() == x.entityName.toLowerCase()) {
              z.show_icon = false;
            }
          });
        });

        }
        else {
          item.is_display = false;
        }
    });
    }
    return trainstop;
  }

  filterAmpDel(element) {
    for (let data of element.l1Filter.filterValues[0].childValues) {
      if (data.selectedFlag == "Y") {
        switch (data.entityId) {
          case 1:
            this.showAmplifiers = true;
            break;
          case 2:
            this.showDeliverables = true;
            break;
          default:
          // code block
        }
      }
      if (data.selectedFlag == "N") {
        switch (data.entityId) {
          case 1:
            this.showAmplifiers = false;
            break;
          case 2:
            this.showDeliverables = false;
            break;
          default:
          // code block
        }
      }
    }
  }

  ngOnDestroy() {
    this.sharedService.isSitemapPackageReset = false;
    this.subscription.unsubscribe();
  }

  breadcrumbsSelectFunction(array) {
    array.forEach((element) => {
      for (let data of element.l1Filter.filterValues[0].childValues) {

        if (data.selectedFlag == "Y" && element.title == "Packages") {
          // this.userPreferenceService.getPackageData().subscribe((response) => {
          //   if(response){
          //     if(response.toLowerCase() == data.entityName.toLowerCase()){
          //       this.packageId = data.entityId;
          //     }
          //      this.breadPackage =response;

          //   }
          // });
          this.breadPackage = data.entityName;
          this.packageId = data.entityId;
        }
        if (data.selectedFlag == "Y" && element.title == "Functions") {
          // this.userPreferenceService.getFunctionData().subscribe((response) => {
          //   if(response){
          //     if(response.toLowerCase() == data.entityName.toLowerCase()){
          //       this.functionId = data.entityId;
          //     }
          //      this.breadFunction =response;

          //   }
          // });
          this.breadFunction = data.entityName;
          this.functionId = data.entityId;
        }
        if (element.title == "Attributes") {
          this.filterAttributes(element);
        }
        if (element.title == "Amplifiers / Deliverables") {
          this.filterAmpDel(element);
        }
        // if(data.selectedFlag == "Y" && element.title == "Packages"){
        //     this.breadPackage = data.entityName;
        // }
      }
    });

    if(this.sessionFilterData){
  this.sessionFilterData.forEach((element) => {
    for (let data of element.l1Filter.filterValues[0].childValues) {

      if (data.selectedFlag == "Y" && element.title == "Packages") {

        this.breadPackage = data.entityName;
        this.packageId = data.entityId;
      }
      if (data.selectedFlag == "Y" && element.title == "Functions") {

        this.breadFunction = data.entityName;
        this.functionId = data.entityId;
      }
      if (element.title == "Attributes") {
        this.filterAttributes(element);
      }
      if (element.title == "Amplifiers / Deliverables") {
        this.filterAmpDel(element);
      }
      // if(data.selectedFlag == "Y" && element.title == "Packages"){
      //     this.breadPackage = data.entityName;
      // }
    }
  });
 }
  }

  getSelectedFilters(array) {
    array.forEach((element) => {
      for (let data of element.l1Filter.filterValues[0].childValues) {
        if (data.selectedFlag == "Y" && element.title == "Packages") {
          this.packageId = data.entityId;
        }

        // if (element.title == "Packages") {
        // this.userPreferenceService.getPackageData().subscribe((response) => {
        //   if(response){
        //      if(data?.entityName.toLowerCase() ==  response.toLowerCase()){
        //        data.selectedFlag="Y";
        //      }
        //      else{
        //       data.selectedFlag="N";
        //     }
        //   }
        //   });
        // }

        if (data.selectedFlag == "Y" && element.title == "Functions") {
          this.functionId = data.entityId;
        }

      //   if (element.title == "Functions") {
      //   this.userPreferenceService.getFunctionData().subscribe((response) => {
      //     if(response){
      //          if(data?.entityName.toLowerCase() ==  response.toLowerCase()){
      //             data.selectedFlag="Y";
      //           }
      //           else{
      //            data.selectedFlag="N";
      //          }
      //     }
      //   });
      // }

      }
    });

  }

  getSiteMapData() {
    this.getSelectedFilters(this.filters);
    // if(this.sessionFilterData){
    //   this.getSelectedFilters(this.sessionFilterData);
    // }
    if (
      this.packageId &&
      this.packageId != "" &&
      this.functionId &&
      this.functionId != ""
    ) {
      this.sharedService
        .getSiteMapData(
          this.projectGlobalInfo.projectId
            ? this.projectGlobalInfo.projectId
            : "0",
          this.packageId,
          this.functionId,
        )
        .subscribe(
          (res) => {
            this.searchAPIData = res;
            this.manageSitemapDataByPhase(this.searchAPIData);
            this.resetAttributesFilter();
          },
          (err) => {
            this.prepare = null;
            this.imagine = null;
            this.deliver = null;
            this.run = null;
          }
        );
    }
  }

  manageSitemapDataByPhase(searchAPIData: any) {
    for (let phases of searchAPIData) {
      if (phases.phase_name == "Prepare") {
        this.prepare = phases.trainstops;
        this.prepare = this.displayDeliverables(phases.trainstops);
        this.prepareTrainstopsDisplay("Prepare", phases.trainstops);
      }
      if (phases.phase_name == "Imagine") {
        this.imagine = phases.trainstops;
        this.imagine = this.displayDeliverables(phases.trainstops);
        this.prepareTrainstopsDisplay("Imagine", phases.trainstops);
      }
      if (phases.phase_name == "Deliver") {
        this.deliver = phases.trainstops;
        this.deliver = this.displayDeliverables(phases.trainstops);
        this.prepareTrainstopsDisplay("Deliver", phases.trainstops);
      }
      if (phases.phase_name == "Run") {
        this.run = phases.trainstops;
        this.run = this.displayDeliverables(phases.trainstops);
        this.prepareTrainstopsDisplay("Run", phases.trainstops);
      }
    }
  }

  prepareTrainstopsDisplay(phaseName, trainStops) : void
  {
      let phaseWiseTrainStops = this.masterTrainStops.find(element => element.phase_name == phaseName ).trainstops;

      if(trainStops) {
              phaseWiseTrainStops.forEach(i => {
                let present = trainStops.find(j => j.sequence == i.sequence)

                if(!present) {
                  switch(phaseName.toLowerCase()) {
                    case 'prepare' :
                                    this.prepare.push(i);
                                    this.prepare.sort((a, b) => a.sequence - b.sequence);
                                    break;
                    case 'imagine' :
                                    this.imagine.push(i);
                                    this.imagine.sort((a, b) => a.sequence - b.sequence);
                                    break;
                    case 'deliver' :
                                    this.deliver.push(i);
                                    this.deliver.sort((a, b) => a.sequence - b.sequence);
                                    break;
                    case 'run' :
                                    this.run.push(i);
                                    this.run.sort((a, b) => a.sequence - b.sequence);
                                    break;
                  }
                }
              });

      } else {
        this.masterTrainStops.forEach(element => {
         if(element.phase_name == phaseName ) {
          switch(phaseName.toLowerCase()) {
            case 'prepare' : this.prepare = element.trainstops; break;
            case 'imagine' : this.imagine = element.trainstops; break;
            case 'deliver' : this.deliver = element.trainstops; break;
            case 'run' : this.run = element.trainstops; break;

          }
          }
        });
      }

  }

  goToAmplifier(e){
    if(e.filter_id &&  e.tool_id){
      this.router.navigate(['/marketplace/' + e.filter_id + '/' + e.tool_id]);
    }
  }

  goToDeliverable(deliverable, phase, stop) {

    let function_packageURL = '/' + this.packageId + '/' + this.functionId

    this.cryptUtilService.setItem(
      BUS_MESSAGE_KEY.FUNCTION_PACKAGE,
      function_packageURL,
      "SESSION"
    );

    this.activateTabDataService.setActiveTabData(deliverable);
    this.router.navigate([`/activities/iidr/${phase}/${stop}`], {state: {activityId: deliverable.activity_id},queryParams: { source: "sitemap" }});
  }

}
