export const UPLOAD_POPUP = {
    TITLE: 'ListProject.ManageUsers.Common.Label.UploadUsers',
    CONFIRM_BUTTON: 'Global.Common.Button.Save',
    CANCEL_BUTTON: 'Global.Common.Button.Cancel',
    MESSAGE: 'ListProject.ManageUsers.Common.Label.UploadUsers',
    SUCCESS_MSG: '',
    FAILED_TITLE: ''
}

export const MODAL_PRIMARY_TEXT = 'CreateProject.Dialog.Client.Button.OK';
export const MODAL_ERROR_MSG = 'ListProject.ManageUsers.Common.Error.Message';
export const INTERNAL_USER = 'InternalUser';
export const EXTERNAL_USER = 'ExternalUser';
export const DESC = 'DESC';
export const ASC = 'ASC';
export const DEFAULT_SORT_FIELD = 'startDate';
export const SOFTWARE_ENGG_DESIGNATION = 'SOFTWARE ENGINEER';


export const EXCEL_UPLOAD_SUCCESS_MSG = 'ListProject.ManageUsers.Common.Upload.Success.Message';
export const UPLOAD_USERS = 'ListProject.ManageUsers.Common.Label.UploadUsers';
export const DOWNLOAD_USERS = 'ListProject.ManageUsers.Common.Label.DownloadUsers';

export const WARNING_HEADER_TITLE = 'ListProject.ManageUsers.WarningHeaderTitle';
export const WARNING_PRIMARY_BUTTON = 'Global.Common.Button.Apply';
export const WARNING_SECONDARY_BUTTON = 'Global.Common.Button.Cancel';
export const WARNING_MESSAGE = 'ListProject.ManageUsers.WarningMessage';
export const MANAGE_USERS = {
    MANAGE_USERS_PAGE: 'Manage Users',
    DELETE_ACTION: 'User Delete',
    
}