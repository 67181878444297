import { NotificationDataModel } from './system-notification.model';

export const notificationData: NotificationDataModel =  {
    project: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToMyProject", url: "workspace/my-projects"},
    },
    projectCreated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"},
    },
    projectApprovedRejected: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "workspace/my-projects/"},
    },
    userAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    psg: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToPSG", url: "psg/general-details/engagement/project/"},
    },
    toolGranted: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToTools", url: "trainstops/activities/"},
    },
    activity: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToActivity", url: "trainstops/activities/"},
    },
    metaDataUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Go to Language Settings screen", url: "/language-settings/1"},
    },
    genAIUserAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIUserDeleted: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIUserNotDeleted: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIUserNotAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIBulkUsersAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIBulkUsersNotAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GotoUserList", url: "workspace/my-projects/manage-project/users"},
    },
    genAIProjectAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"}
    },
    genAIProjectNotAdded: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"}
    },
    genAIProjectMSTeamsIdUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"}
    },
    genAIProjectMSTeamsIdNotUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"}
    },
    aipSubUseCasesSynced: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"}
    },
    aipSubUseCasesNotSynced: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"}
    },
    genAIProjectDetailsNotUpdated: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"}
    },
    genAIAmplifierDisabled: {
        metaDataLabels: [],
        redirectionUrlData: {label: "Navigation.Menu.Notification.GoToProject", url: "createproject/"}
    }

}

export const notificationEventsTypes = {
    projectApproved: "PROJECT_APPROVED",
    projectCreated: "PROJECT_CREATED",
    projectRejected: "PROJECT_REJECTED",
    toolGranted: "TOOL_PROVISIONED",
    userAdded: "USER_ADDED",
    psgGenerated: "PSG_GENERATED",
    psgUpdated: "PSG_UPDATED",
    usersAdded: "USERS_ADDED",
    jiraExportSuccess: "JIRA_EXPORT_SUCCESS",
    jiraExportFailed: "JIRA_EXPORT_FAILED",
    testScenarioGenerationSuccess: "TEST_SCENARIO_GENERATION_SUCCESS",
    testScenarioGenerationFailed: "TEST_SCENARIO_GENERATION_FAILED",
    metaDataUpdated: "METADATA_UPDATED",
    genAIUserAdded: "GENAI_USER_ADDED",
    genAIUserDeleted:"GENAI_USER_DELETED",
    genAIUserNotDeleted:"GENAI_USER_NOT_DELETED",
    genAIUserNotAdded: "GENAI_USER_NOT_ADDED",
    genAIBulkUsersAdded: "GENAI_BULK_USERS_ADDED",
    genAIBulkUsersNotAdded: "GENAI_BULK_USERS_NOT_ADDED",
    genAIProjectAdded: "GENAI_PROJECT_ADDED",
    genAIProjectNotAdded: "GENAI_PROJECT_NOT_ADDED",
    genAIProjectMSTeamsIdUpdated: "GENAI_PROJECT_MSTEAMSID_UPDATED",
    genAIProjectMSTeamsIdNotUpdated: "GENAI_PROJECT_MSTEAMSID_NOT_UPDATED",
    aipSubUseCasesSynced: "AIP_SUB_USE_CASES_SYNCED",
    aipSubUseCasesNotSynced: "AIP_SUB_USE_CASES_NOT_SYNCED",
    genAIProjectDetailsNotUpdated:"GENAI_PROJECT_DETAILS_NOT_UPDATED",
    genAIAmplifierDisabled: "GENAI_AMPLIFIER_DISABLED"
}

export const PROJECT_TOOL = "A project tool";