/* -- ANGULAR CORE -- */
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';

/* -- APPLICATION MODULES -- */
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { HttpServiceHelper } from './types/common/HttpServiceHelper';
import { AuthenticationModule } from './authentication.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ErrorHandlerService } from './shared/services/error-handling.service';
import { environment } from 'src/environments/environment';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpPostService } from './multi-tranlsate-http-post-service';

function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpPostService(http, [
        {prefix: `${environment.API_MICROSERVICE_URL.ADMIN}/i18n/Landing/`, suffix: ".json"},
        {prefix: `${environment.API_MICROSERVICE_URL.ADMIN}/i18n/`, suffix: ".json"}
      ]);
  }

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        ScrollingModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AuthenticationModule,
        AppRoutingModule,
        SharedModule.forRoot(),
        FontAwesomeModule,
    ],
    providers: [
        HttpServiceHelper,
        TranslateService,
        { provide: ErrorHandler, useClass: ErrorHandlerService }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
