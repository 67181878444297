import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import { Subscription } from 'rxjs';
import { DefineRoadmapService } from '../../define-roadmap.service';
import { SprintGenerationPopupComponent } from '../../sprint-generation-popup/sprint-generation-popup.component';
import { ASCEND_MODES, SPRINT_CAPACITY} from '@projects/projectsummary/src/app/core/utility/constants';
import { LoggerService } from '@projects/shared-lib/src/public-api';
import { BUS_MESSAGE_KEY } from 'src/app/shared/constants/message-bus';
import { MessagingService } from 'src/app/shared/services/messaging.service';

@Component({
  selector: "app-mosco-prioritization",
  templateUrl: "./mosco-prioritization.component.html",
  styleUrls: ["./mosco-prioritization.component.scss"],
})
export class MoscoPrioritizationComponent implements OnInit {
  dataObject: any;
  dataSource: any;
  cols: string[] = ["proirity_name", "is_enabled"];
  doReset: boolean;
  context: any;
  viewMode: any;
  projectId: number;
  isGenerationDone: boolean = false;
  @ViewChild("snackBarTemplate") snackBarTemplate: TemplateRef<any>;
  isAleastL1Confirmed: boolean = false;
  sprintCapacitySuccess = SPRINT_CAPACITY;
  isAllL1Finalized:boolean=false;
  ASCEND_MODES = ASCEND_MODES;
  constructor(
    private defineRoadmapService: DefineRoadmapService,
    private globalContext: ActivitiesGlobalContextService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private loggerService: LoggerService,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    this.globalContext.getGlobalActivityContext().subscribe((data) => {
      this.context = data;
      this.viewMode = this.context?.viewmode;
      this.projectId = this.context.project.projectId; //3568
    });

    this.defineRoadmapService
      .getMoscowData(this.projectId)
      .subscribe((data) => {
        this.dataObject = data.data;
        this.defineRoadmapService.isMoscowApplied = this.dataObject.isConfirm
          ? true
          : false;
        this.dataSource = new MatTableDataSource<any>(this.dataObject.priority);

        //when data is null then do resetData
        if (this.dataObject.priority == null) {
          this.resetData();
        }
        this.resetEnable();
      });

    this.defineRoadmapService.getIsAllL1Finalized().subscribe((data)=>{
      this.isAllL1Finalized=data
    })

    this.defineRoadmapService.getAtleastOneL1Finalized().subscribe(data => {
      this.isAleastL1Confirmed = data;
    });

    this.defineRoadmapService.getSprintGenerationStatus().subscribe((data) => {
      this.isGenerationDone = data;
    });
  }

  resetData() {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
    this.dataObject.priority.forEach((item, index) => {
      if (index < 3) {
        item.is_enabled = true;
      } else {
        item.is_enabled = false;
      }
    });
    // this.doReset = false;
  }

  resetEnable() {
    for (let i = 0; i < this.dataObject.priority.length; i++) {
      if (this.dataObject.priority[i].is_enabled && i < 3) {
        this.doReset = false;
      } else if (!this.dataObject.priority[i].is_enabled && i >= 3) {
        this.doReset = false;
      } else {
        this.doReset = true;
        break;
      }
    }

    if (this.dataObject.isConfirm) {
      this.doReset = false;
    }

    if (this.isGenerationDone) {
      this.doReset = true;
    }
  }

  toggle(element, event) {
    this.dataObject.priority.forEach((item, index) => {
      if (element.proirity_name === item.proirity_name) {
        this.dataObject.priority[index].is_enabled = event.checked;
      }
    });
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(true)
    this.resetEnable();
  }

  onNextStep() {
    let obj = {
      parentIndex: 1,
      childIndex: 0,
    };
    this.defineRoadmapService.setSwitchParent(obj);
  }

  onConfirm() {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false);
    if (this.isGenerationDone) {
      let data = {
        title: "GenerateReleasePlan.Popup.UpdateDataWarning.Popup.Title",
        for: "Activities.UserStoryLibrary.MoSCoWPriotization.Title",
        description:
          "GenerateReleasePlan.Popup.UpdateDataWarning.Popup.Description",
      };

      const dialogRef = this.dialog.open(SprintGenerationPopupComponent, {
        width: "700px",
        height: "auto",
        data: data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == "confirm") {
          let postData = this.dataObject.priority;
          this.defineRoadmapService
            .saveMoscowData(this.projectId, { data: postData })
            .subscribe(
              (res) => {
                this.defineRoadmapService.isMoscowApplied = true;
                this.dataObject.isConfirm = true;
                this.doReset = false;
                setTimeout(() => {
                  this._snackBar.openFromTemplate(this.snackBarTemplate, {
                    duration: 5000,
                  });
                }, 5000);
                this.onNextStep();
              },
              (err) => {
                this.loggerService.log(err);
              }
            );
        }
      });
    } else {
      let postData = this.dataObject.priority;
      this.defineRoadmapService
        .saveMoscowData(this.projectId, { data: postData })
        .subscribe(
          (res) => {
            this.defineRoadmapService.isMoscowApplied = true;
            this.dataObject.isConfirm = true;
            this.doReset = false;
            setTimeout(() => {
              this._snackBar.openFromTemplate(this.snackBarTemplate, {
                duration: 5000,
              });
            }, 5000);
            this.onNextStep();
          },
          (err) => {
            this.loggerService.log(err);
          }
        );
    }
  }
  
  startLoader(){
    this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, true);
  }
  stopLoader(){
    this.messagingService.publish(BUS_MESSAGE_KEY.LOADER, false);
  }

  ngOnDestroy() {
    this.defineRoadmapService.setIsAnyChangesInGSPFlyout(false)
  }
}
