import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DocumentRepositoryService } from '@projects/document-repository/src/app/document-repository/document-repository.service';
import { ProjectSummaryService } from '@projects/projectsummary/src/app/projectsummary/project-summary.service';

import { ProjectGlobalInfoModel } from "src/app/shared/model/project-global-info.model";
import { PassGlobalInfoService } from 'src/app/shared/services/pass-project-global-info.service';
import { TranslateService } from '@ngx-translate/core';
import { NAVIGATOR_SCREEN_DETAILS } from '../../utility/constants';
import { LanguageSettingsService } from '@projects/language-settings/src/app/language-settings/language-settings.service';
@Component({
  selector: 'lib-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonHeaderComponent implements OnInit, OnChanges {
  showDocInputSearch: boolean = false;
  isdropdownOpen: boolean = false;
  projectGlobalInfo: ProjectGlobalInfoModel = new ProjectGlobalInfoModel();
  documentData:any=[];
  documents:any;
  searchInputPlaceholder: any;
  noSearchResult: any;
  searchParams:'' ;
  currentScreenName: string = NAVIGATOR_SCREEN_DETAILS[4]?.name;
  @Input() title: string;
  @Input() packageMethodId: number;
  @Input() showNavigator?: boolean;
  projectId: number;
  languageId: number = 1;

  constructor(
    private router: Router,
    private globalData: PassGlobalInfoService,
    private projectSummaryService: ProjectSummaryService,
    private documentRepositoryService: DocumentRepositoryService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private languageSettingsService: LanguageSettingsService
  ) { }

  ngOnInit(): void { 
    this.globalData.share.subscribe(data => {
      this.projectGlobalInfo = data;
      this.projectGlobalInfo.projectId = this.projectGlobalInfo.projectId ? this.projectGlobalInfo.projectId : '0';
    });

    this.projectId = this.route.snapshot.paramMap.get('id') ? +this.route.snapshot.paramMap.get('id') : 0;
    this.languageSettingsService.getSelectedLanguage().subscribe(language => {
      if (language?.name) {
        this.languageId = language?.id;
      }
    })
    this.getTableData();

    this.searchInputPlaceholder = 'DocumentRepository.Header.Placeholder.SearchDocument';
            this.noSearchResult = 'Global.Common.Label.NoResultFound';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.packageMethodId.previousValue && changes.packageMethodId.previousValue!==changes.packageMethodId.currentValue){
      this.packageMethodId = changes.packageMethodId.currentValue;
      this.getTableData();
    }
  }

  goToPreviousPage() {
    if(this.projectId == 0) {
      this.router.navigate([`/platform-admin`])
    }
    else
      this.router.navigate([`/projectsummary/${this.projectId}`]);
  }

  showSearchInputBox() {
    if (this.showDocInputSearch) {
      this.showDocInputSearch = false;
      return this.showDocInputSearch;
    } else {
      this.showDocInputSearch = true;
      return this.showDocInputSearch;
    }
  }

  closeSearchEvent($event) {
    this.showDocInputSearch = $event;
  }

  selectSearchEvent($event){
    this.searchParams=$event;
    this.documentRepositoryService.setDocumentName($event);
  }

  getTableData() {
    let params = new HttpParams();
    if(this.projectId == 0 && this.packageMethodId)
      params = params.append('packageMethodId', this.packageMethodId);

    this.documentRepositoryService.searchDocument(this.projectId, this.languageId, params).subscribe((docResponse)=>{
         this.documents = docResponse;
         if(this.documents)
         {
           this.documentData = this.documents?.data;
         }
    });
  }
}
