import { CryptUtilService } from 'src/app/shared/services/crypt-util.service';
import { notificationEventsTypes } from './../../model/notification.config';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { SystemNotificationService } from '../../services/system-notification.service';
import { ProjectGlobalInfoModel } from '../../model/project-global-info.model';
import { PassGlobalInfoService } from '../../services/pass-project-global-info.service';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import { UserPreferenceService } from '../../services/user-preferences.service';
import { HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { MyProjectsService } from '@projects/workspace/src/app/my-projects/my-projects.service';
import { LANGUAGE_DATA } from '../../../../../projects/psg/src/app/core/utility/constants';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() notification?: any;
  @Input() showNotifications: boolean;
  public timeDiffInNotification: any;
  public notificationEvents = { ...notificationEventsTypes };
  private timenow = Date.now();
  projectGlobalInfo: ProjectGlobalInfoModel = new ProjectGlobalInfoModel();
  functionId: number;
  functionName: string;
  languageId: number = 1;
  user;
  masterUserRoledata = [];
  projectCreatedNotification: boolean = false;
  
  constructor(private router: Router,
    private sysNotificationService: SystemNotificationService,
    private globalData: PassGlobalInfoService,
    private globalContext: ActivitiesGlobalContextService,
    private cryptUtilService: CryptUtilService,
    private userPreferences: UserPreferenceService,
    private myProjectsService: MyProjectsService) { }

  ngOnInit(): void {
    if(this.notification) {
      let utcCreatedOnDate = this.notification?.createdOn.replace('T',' ')
      utcCreatedOnDate = utcCreatedOnDate+' UTC'
      let timeDiff = Math.abs(Date.parse(utcCreatedOnDate) - this.timenow);
      if(Math.floor(timeDiff / 36e5) > 0){
        if(Math.floor(timeDiff / 36e5)>=24) {
          this.timeDiffInNotification = `${Math.floor(Math.floor(timeDiff / 36e5) /24)} ${Math.floor(Math.floor(timeDiff / 36e5) /24)>1 ? 'days' : 'day'} ago`
        } else {
          this.timeDiffInNotification = `${Math.floor(timeDiff / 36e5)} ${Math.floor(timeDiff / 36e5)>1 ? 'hours' : 'hour'} ago`
        }
      } else if (Math.floor(timeDiff / 60000) > 0) {
        this.timeDiffInNotification = `${Math.floor(timeDiff / 60000)} ${Math.floor(timeDiff / 60000)>1 ? 'mins' : 'min'} ago`
      } else {
        this.timeDiffInNotification = `just now`
      }
    //2856173 - restricting the user to see the go to project button in the notification when project is created.
    if(this.notification.event == notificationEventsTypes.projectCreated) {
      this.projectCreatedNotification = true;
    }

    }
  }

  notificationRedirect(notificationData) {
   this.sysNotificationService.readNotification(notificationData.id).subscribe((res) => {
       if (res) {
         if(notificationData.event != notificationEventsTypes.psgGenerated || notificationData.event != notificationEventsTypes.psgUpdated){
          this.sysNotificationService.setNotificationData(notificationData);
        }
        this.sysNotificationService.setNotificationAction(true);
        this.sysNotificationService.setIsRedirectLinkClicked(true); 
        if(notificationData?.tabCode){
          this.functionId = notificationData?.functionId;
          this.functionName = notificationData?.functionName;
          this.user = this.cryptUtilService.getItem('userInfo', 'SESSION');
          let params = new HttpParams().set("userid", this.user?.userId.toString());
          this.sysNotificationService.getProjectInfo(this.notification?.projectId, this.languageId, params).subscribe((projectInfo)=> {
            if(projectInfo){
              this.setProjectData(projectInfo[0]);
            }
            this.router.navigate([notificationData.redirectionUrlData.url]);
          });
        } else {
          this.router.navigate([notificationData.redirectionUrlData.url]);
        } 
      }
    })
  }

  deleteNotification(notificationId) {
    this.sysNotificationService.deleteNotification(notificationId).subscribe((res) => {
      if (res) {
        this.sysNotificationService.setNotificationAction(true);
      }
    })
  }

  setProjectData(projectInfo){
      forkJoin({ response: this.sysNotificationService.getUser(this.languageId), result: this.myProjectsService.getMasterUserRole(LANGUAGE_DATA.ENGLISH) }).subscribe(({ response, result }) => {
        this.masterUserRoledata = result?.data;
          if(response) {
          let userRoleInfoObj = {
            userEmail: response.userEmail,
            role: response?.role?.length && response?.role[0],
            menu: response.menu,
            package: response?.package?.length && response?.package,
            roleSubset: response?.roleSubset,
            accessObjects: response?.accessObjects,
            englishLanguage: this.masterUserRoledata.find(ele => {
              return ele?.id === response?.role?.[0]?.id
          })
        }
      
    this.projectGlobalInfo.userRoleInfo = userRoleInfoObj;
    this.projectGlobalInfo.projectId = projectInfo.projectid;
    this.projectGlobalInfo.projectName = projectInfo.projectName;
    this.projectGlobalInfo.clientName = projectInfo.clientName;
    this.projectGlobalInfo.managerName = projectInfo.mgrusername;
    this.projectGlobalInfo.packageName = projectInfo.erpPackage;
    this.projectGlobalInfo.clientUrl = projectInfo.clientLogoURL;
    this.projectGlobalInfo.role = projectInfo.projectRole;
    this.globalData.updateGlobalData(this.projectGlobalInfo);
    this.globalContext.updateGlobalActivityContext({
      packageId: projectInfo.package_id,
      projectId: projectInfo.projectid,
      functionId: this.functionId,
      functionName: this.functionName,
    });
    this.cryptUtilService.setItem('Previous-Session-Function-Selected-IIDR-Project', this.functionName, 'SESSION');
    this.globalData.setProjectDetails({
      project: this.projectGlobalInfo,
      userRoleInfo: this.projectGlobalInfo.userRoleInfo
    });
  }});
  }

}
