import { 
    LAYOUT_TYPE, 
    LAYOUT_DELIVER_SUB_NAV, 
    LAYOUT_IMAGINE_SUB_NAV, 
    LAYOUT_INSIGHTS_SUB_NAV, 
    LAYOUT_RUN_SUB_NAV 
} from './layout/standard/standard-constants.model';

export {
    LAYOUT_TYPE, 
    LAYOUT_DELIVER_SUB_NAV, 
    LAYOUT_IMAGINE_SUB_NAV, 
    LAYOUT_INSIGHTS_SUB_NAV, 
    LAYOUT_RUN_SUB_NAV 
} 

import DELIVER_LAYOUT_CONFIG from './layout/standard/deliver';
import IMAGINE_LAYOUT_CONFIG from './layout/standard/imagine';
import INSIGHTS_LAYOUT_CONFIG from './layout/standard/insights';
import RUN_LAYOUT_CONFIG from './layout/standard/run';


/* -- FINAL CONFIG OBJECT -- */
export const LAYOUT_CONFIGURATION = {
    [LAYOUT_TYPE.INSIGHTS]: INSIGHTS_LAYOUT_CONFIG,
    [LAYOUT_TYPE.IMAGINE] : IMAGINE_LAYOUT_CONFIG,
    [LAYOUT_TYPE.DELIVER] : DELIVER_LAYOUT_CONFIG,
    [LAYOUT_TYPE.RUN]     : RUN_LAYOUT_CONFIG,
};

export const PHASE_ACTIVITY_CONST = {
    [LAYOUT_TYPE.INSIGHTS]: {
        title: 'Prepare',
        numberIcon: 'images/activity-icons/Circles_Insights.svg',
        entitySequence: 1
    },
    [LAYOUT_TYPE.IMAGINE]: {
        title: 'Imagine',
        numberIcon: 'images/activity-icons/Circles_Imagine.svg',
        entitySequence: 2
    },
    [LAYOUT_TYPE.DELIVER]: {
        title: 'Deliver',
        numberIcon: 'images/activity-icons/Circles_Deliver.svg',
        entitySequence: 3
    },
    [LAYOUT_TYPE.RUN]: {
        title: 'Run',
        numberIcon: 'images/activity-icons/Circles_Run.svg',
        entitySequence: 4
    },
}
export const FALSE = "false";
export const ACT_STATUS = ["completed", "skipped"];
export const INCOMPLETE = 'incomplete';

export const STATES = [
    {
        title : 'TrainStop.Header.CurrentText',
        statusIcon : 'assets/iidr/current.png',
        description : 'present state icon',
        separator: true
    },
    {
        title : 'TrainStop.Header.CompletedText',
        statusIcon : 'assets/iidr/tick.png',
        description : 'completed state icon',
        separator: true
    },
    {
        title : 'TrainStop.Header.SkippedText',
        statusIcon : 'assets/iidr/skip1_new.png',
        description : 'skipped state icon',
        separator: false
    }
]